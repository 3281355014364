@import "carousel.scss";
@import "_variables.scss";
@import "_mixins.scss";
body {
  padding-bottom: 60px !important;
  min-height: 100%;
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
.border-radius-5{
  border-radius: 5rem;
}
.text-secondary {
  color: #767B84 !important;
}
.text-green {
  color: #308C57;
}
.fa-bell{
  text-decoration: none;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  .badge-red {
    position: absolute;
    top: -5px;
    left: 8px;
    padding: 4px 4px;
    border-radius: 50%;
    background-color: red;
    color: white;
  }
}
.footer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 92px;
  padding: 0 15px;
  background-color: #ffff;
  border-top: 1px solid #d4d1d1;
  align-items: center;
  justify-content: center;
  bottom: 0;
}
.footer p.copyright {
  margin: 0;
}
.custom-bg-light {
  background-color: #ffffff;
  padding-top: 0 !important;
  padding-bottom: 1px !important;
  -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 1px 18px -6px #aca6a6;
}
.dosing-history-form-headings{
  font-family: "Source Sans Pro", sans-serif;
  font-size: 48px;
  font-weight: bold;
}
.dosing-history-p{
  font-size: 22px;
  font-family: 'Poppins';
  font-weight: 400;
}


.load-more{
  .btn {
    background-color: #308c57;
    border-color: #308c57;
  }
}

// Swal 
.swal-modal {
  border-radius: 1rem;
}
.swal-title{
 font-family: 'Source Sans Pro'; 
}
.swal-icon img {
    max-width: 13%;
    max-height: 13%;
}
.swal-footer{
 text-align: center;
}
.swal-button{
  border-radius: 5rem;
  // background-color: #1473E6; 
}
.swal-button:not([disabled]):hover{
  // background-color: #1473E6; 

}
.swal-button--danger{
  background-color: #1473E6;
}
.swal-button--danger:not([disabled]):hover{
  background-color: #1473E6;
}
.swal-button--cancel{
  background-color: white;
}
.swal-button--cancel:not([disabled]):hover {
  background-color: white;
}
.swal-button--confirm{
  background-color: #1473E6;
}
.swal-button--confirm:not([disabled]):hover{
  background-color: #1473E6;
}
/* Header Css */
.pp-img{
  position: relative;
  top:11px;
  border-radius: 100px;
  box-shadow: 0 8px 19px -10px #777;
}
.nav-link {
  display: block;
  padding: 1.5rem 1rem;
  margin-bottom: -1px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-weight: 500;
  color: black;
  background-color: unset;
  border-radius: 0;
  border-bottom: 3px solid #1473e6;
}
.nav-pills .nav-link {
  font-weight: 500;
  color: black;
}
nav .dropdown-menu.show{
  padding: 0px;
}
// Notification side bar

.main-right-side-notice {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    visibility: hidden;
    opacity: 0;
    transition: visibility ease 0.275s, opacity ease 0.275s;


  .notice-bar{
    position: absolute;
    top: 0;
    right: -488px;
    width: 100%;
    max-width: 488px;
    background: #ffffff;
    padding: 30px 48px;
    height: 100%;
    overflow-y: auto;
    transition: right 0.275s ease;

    .header{
      display: flex;

      h3{
        font-size: 32px;
        margin-top: 0px;
      }
      i{
        border-radius: 50%;
        padding: 14px;
        background: #f0f0f0;
        color: #666666;
        margin-left: auto;
        font-size: 10px;
      }
      a{
        margin-left: auto;
      }
    }
    .notificaton-group{
      background: #eff2f5;
      border-radius: 12px;
      margin-top: 30px;
      .single-notification.read-notification {
        background-color: white;
        border: 1px solid #2a2a2a;
        border-radius: 10px;
      }
      .single-notification {
        display: flex;
        padding: 20px 20px 15px;

        .left-col{
          display: flex;
          flex-direction: column;
          align-items: baseline;
          cursor: pointer;

          i{
            height: 48px;
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #dbe4ed;
            color: #1473E6;
            border-radius: 50%;
            font-size: 22px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
        .right-col{
          color: #2a2a2a;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}



.main-right-side-notice.noti-drawer-open {
  visibility: visible;
  opacity: 1;
  .notice-bar { 
    right: 0;
  }
}


.physician-singnp-btn .dropdown-toggle::after {
  content: none;
}

.physician-singnp-btn{

  .dropdown-menu {
    margin-left: -73px;
    text-align: center;
    margin-top: 0px;
    padding: 0px;
    .nav-link{
      padding: 1rem 1rem;
    }
  } 
  
}
.patient-singup{
  padding: 5rem 12.7rem;
}


.physician-singnp-btn ul li a:hover{
  border-radius: unset;
}
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}



.navbar-light .navbar-brand {
  /* padding-left: 114px;*/
  padding: 0;
}
.form-inline {
  /* padding-right: 114px;*/
}

/* Patient Registration Form */

// toggle button
.switch {
  position: relative;
  display: inline-block;
}
.switch-input {
  display: none;
}
.switch-label {
  display: block;
  width: 76px;
  height: 17px;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
}
.switch-label::before,
.switch-label::after {
  content: "";
  display: block;
  position: absolute;
  cursor: pointer;
}
.switch-label::before {
  width: 100%;
  height: 100%;
  color: black;
  background-color: #dedede;
  border-radius: 9999em;
  transition: background-color 0.25s ease;
  content: "NO";
  text-indent: 59%;
  font-size: 12px;
  line-height: 2;
  font-weight: bold;
}
.switch-label::after {
  top: 0;
  left: 0;
  width: 24px;
  height: 22px;
  margin-top: 1px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: left 0.25s ease;
  transition: left 0.25s ease;
}
.switch-input:checked + .switch-label::before {
  background-color: $color_green_approx;
  content: "YES";
  font-size: 12px;
  color: white;
  font-weight: bold;
  line-height: 2;
  text-indent: 14%;
}
.switch-input:checked + .switch-label::after {
  left: 49px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 5rem;
}

.slider.round:before {
  border-radius: 5rem;
}
.absolute-no {
  position: absolute;
  left: 0;
  color: black;
  text-align: right !important;
  font-size: 15px;
  width: calc(100% - 25px);
  height: 100px;
  line-height: 34px;
  cursor: pointer;
}

.light-blue {
  background-color: #1473e6;
}

.card.card-border {
  border-radius: 1rem;
  border: none;
}

.group-card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #308c57;
  background-clip: border-box;
  border-radius: 2rem;
  width: 80%;

  img {
    height: 50%;
    width: 40%;
  }
}
.profile-dp{
  box-shadow: 0 1px 18px -6px #aca6a6;
  border-radius: 100px;
  margin-right: 1rem;
}
.MuiFormControl-root{
    border: 1px solid #d0d0dc !important;
    border-radius: 7px;
    height: calc(1.5em + 2rem + 2px);
    width: 100%;
}
.height-switch{
  .switch-input:checked + .switch-label::before {
    content: "Feet";
  }
  .switch-label::before{
    content: "Cm";
  }
}
// On Focus label

.custom-form-row {
  position: relative;
}

.custom-form-row i {
  position: absolute;
  top: 14px;
  right: 12px;
}

.custom-form-row .fa-question-circle {
  top: 12px;
}

.form-control,
.ant-picker-input > input {
  font-family: $font_0;
  font-weight: 400;
  color: $black;

  &::placeholder {
    color: $black;
    opacity: 0.5;
  }
}


select.form-control {
  option:disabeled {
    opacity: 0.5;
  }
}

.form-control.custom-form-control:focus,
.form-control:focus,
.custom-form-row .ant-picker-focused {
  background-color: #fff;
  border-color: #1473e6;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.custom-form-row .ant-picker:hover {
  border: 1px solid #d0d0dc;
}

.label-helper {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease bottom, 0.2s ease opacity, 0.2s ease visibility;
  top: -24px;
  left: 10px;
  z-index: 1;
  bottom: auto !important;
}

.form-control:focus + .label-helper,
.form-control:invalid + .label-helper {
  top: -15px !important;
  font-size: 14px;
  line-height: 1;
  opacity: 1 !important;
  position: absolute !important;
  background-color: white;
  color: #1473e6;
  display: block !important;
}

textarea.form-control:focus + .label-helper,
textarea.form-control:invalid + .label-helper{
  top: -24px !important;
  bottom: auto !important;
}

.form-control:focus.daily-dose-form:focus + .label-helper,
.form-control:invalid.daily-dose-form:invalid + .label-helper {
  bottom: 77% !important;
}

.form-control:focus.custom-form-control:focus + .label-helper,
.form-control.custom-form-control:not(:placeholder-shown):not(select):not(.ant-picker)
  + .label-helper,
.MuiInputBase-input.MuiInput-input + .label-helper,
.custom-form-row.has-number .label-helper,
.form-control:invalid.custom-form-control:invalid + .label-helper,
select.form-control:not(.disabled-option) + .label-helper,
.custom-form-row:focus-within .label-helper {
  bottom: 66%;
  font-size: 14px;
  line-height: 1;
  opacity: 1;
  visibility: visible;
  position: absolute;
  background-color: white;
  color: #1473e6;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #1473E6;
  color: white;
}
.imgPreview {
  text-align: center;
  margin: 4px 0px;
  img {
    width: 50%;
    height: 50%;
  }
}
.custom-form-row {
  position: relative;

  &:focus-within {
    .ant-picker {
      background: #ffffff;
    }

    .react-tel-input .form-control,
    .react-tel-input .flag-dropdown {
      background-color: #ffffff;
    }
  }
}
.react-select__control {
  border-radius: 6px;
}
.css-yk16xz-control {
  background-color: $color_light_green_color !important;
}

.stepper-form-headings.custom-welcome-heading {
  font-size: 2.5rem;
}

// Login

.card.custom-login-card {
  border: 0px;
  border-radius: 8px;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.copyright {
  color: #767b84;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.react-tel-input .form-control {
  height: calc(1.5em + 2rem + 2px);
  width: 100%;
}
.card-body.custom-inner-content .switch {
  text-align: left;
}

@include largeScreenOnly {
  .custom-margin {
    margin-right: 46px;
  }
}
.patient-login-btn{
  text-align: center;
}
.auth-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  padding-top: 70px;
  .auth-role-button {
    margin-left: -11px;
  }
  .custom-card {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 36px;
    width: 100%;
    max-width: 449px;
    position: relative;
    box-shadow: 0px 0px 25px 0px #80808052;
    ul {
      margin-left: 22px;
      li {
        list-style: none;
      }
      li span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-family: "Source Sans Pro";
      }
    }
    i {
      color: #80808052;
    }
  }
  .custom-card.card-width {
    max-width: 544px;
  }
}
.ant-picker-suffix {
  color: $color_blue_approx !important;
}

.custom-verify-modal {
  .paragraph-help {
    font-size: 23px;
  }
}

.patient-shoop-dashboard{
  .daignose{

    h5{
      font-size: 22px;
      font-family: 'Poppins';
      font-weight: 500;
      span{
        font-size: 22px;
        font-family: 'Poppins';
        font-weight: 500;
        color: #1473e6;
      }
    }
  }
  .mdcn{

    p{
      font-size: 16px;
      font-family: 'Poppins';
      font-weight: 400;
      color: #1473e6;
    }
  }
}

.physicain-patient-profile.patient-physicain-profile{
  .btn-primary{
    margin-right: 5px;
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle { 

    margin-right: 5px;

  }
  .btn-primary:hover {
    margin-right: 5px;

  }
}

.dashboard-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  // background-image: url("../images/Untitled-1.png");
  .dashboard-bg-img{
    background-image: url("../images/Untitled-1.png");
    padding: 40px 0px;
    background-repeat: no-repeat;
    background-size: cover;
       .col-md-7{
         border-right: 2px solid #C7C8C9;
       }
       .db-que{
         font-size: 26px;
       }
  }
  .card-body{
    border:none;
  }

  
  .auth-form-phyiscian-patient.patient-physician{
    padding-top: 0px;
  }
  .physcian-image {
    img {
      cursor: pointer;
    }
      p{
        cursor: pointer;
        font-size: 24px;
        font-family: 'Poppins';
        font-weight: 600;
      }
  }
  .btn {
    right: 25px;
    position: relative;
    background-color: $color_dark_yellow;
    color: black;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5rem;
    text-align: center;
  }
  .btn:hover {
    background-color: blue;
    color: white;
  }
  .media-body {
    flex: 1;
  }
  .text-layout {
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 400;
  }
  .how-your-feeling {
    text-align: center;
    img {
      padding: 10px;
    }
  }

  .step-feeling {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    flex-wrap: wrap;
    transform: translateX(18px);
    max-width: 170px;
    margin: 0 auto;

    & > div {
      position: relative;

      &:after {
        content: "";
        height: 2px;
        background: #767B84;
        width: calc(100% + 35px);
        position: absolute;
        bottom: calc(100% + 13px);
        left: 0;
      }

      &:last-of-type:after {
        content: none;
      }
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      appearance: none;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      min-width: 68px;
      z-index: 1;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      width: 18px;
      top: -24px;
      left: -9px;
      height: 18px;
      border: 1px solid #767B84;
      border-radius: 100%;
      background: #767B84;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 22px;
      height: 22px;
      left: 0;
      bottom: calc(100% - 3px);
      background: #2c2d2f;
      position: absolute;
      border: 7px solid #1473e6;
      border-radius: 100%;
      transition: all 0.2s ease;
      margin-left: 0px;
      padding: 11px;
      transform: translateX(-19px) scale(0);
    }

    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
    }

    [type="radio"]:checked + label:after {
      opacity: 1;
      transform: translateX(-19px) scale(1);
    }
   
    .img-1 {
      position: absolute;
      bottom: calc(100% + 34px);
      left: 0;
      transform: translateX(-39%);
    }

    .margin-left-1 {
      transform: translateX(-50%);
      display: inline-flex;
    }
    .margin-left-2 {
      transform: translateX(-50%);
      display: inline-flex;
    }
    .margin-left-3 {
      transform: translateX(-50%);
      display: inline-flex;
    }
    .margin-left-4 {
      transform: translateX(-50%);
      display: inline-flex;
    }
    .margin-left-5 {
      transform: translateX(-50%);
      display: inline-flex;
    }
  }
  .section-2{
    background-color: white;
    padding: 0px 0px 15px 0px;
        .green-line{
          background-color: #308C57;
          text-align: center;
          color: #fff;
          font-family: 'Poppins';
          font-size: 18px;
          margin-bottom: 30px;
        }
        .blue-line{
          background-color: #1473E6;
          text-align: center;
          color: #fff;
          font-family: 'Poppins';
          font-size: 18px;
          margin-bottom: 30px;
        }
        .btn-primary-edit{
          background-color: #fff;
          margin: 7px 35px;
          padding: 2px 24px;
          color: #308C57;      
        }
        .btn-primary-blue {
          background-color: #fff;
          margin: 7px 35px;
          padding: 2px 24px;
          color: #1473E6;
          border: none;
          border-radius: 30px;      
        }
        .fa-heart{
          margin: 0px 10px;
        }
  }
  .horizonatl-stepper{
    max-width: 850px;
    margin: 0 auto;
  }
  .md-stepper-horizontal {
    display:table;
    width:100%;
    margin:0 auto;
    background-color:#FFFFFF;
    box-shadow: 0 3px 24px -6px rgba(0,0,0,.50);
    border-radius: 30px;
    a {
      position: relative;
      cursor: pointer;
    
      &:after {
        content: attr(title);
        display: block;
        font-size: 14px;
        color: #333;
        position: absolute;
        right: 0;
        bottom: calc(100% + 5px);
        padding: 8px;
        max-width: 300px;
        background: rgba(241, 204, 20, 0.8);
        border-radius: 5px;
        font-family: "Poppins", sans-serif;
        width: max-content;
        opacity: 0;
        transition: all ease 0.275s;
        line-height: 1.3;
        visibility: hidden;
        cursor: pointer;
      }
      &:hover:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .md-stepper-horizontal .md-step {
    display:table-cell;
    position:relative;
    padding:10px;
  }
  
  .md-stepper-horizontal .md-step:first-child {
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
  }
  .md-stepper-horizontal .md-step:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .md-step.active{
    background:#40B46E;
     .fa-check{
       color: white;
       font-size: 24px;
     }
  }
  .md-step.inactive{
    background:#1473E6;
    .fa-check{
      visibility: hidden;
      font-size: 24px;
    }
  }
  .md-step{
      border: 2px solid #fff;
    }
  .md-stepper-horizontal .md-step-circle {
    text-align: center;
     
  }
  .btn-primary-update{
    padding: 8px 34px;
    margin: 10px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    background-color: #1473E6;
    color: white;
    border: none;
    border-radius: 30px;
  }
  .btn-primary-hide{
    padding: 8px 34px;
    margin: 10px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    border:none;
    border-radius: 30px;
    background-color: rgb(241, 195, 76);
  }
  .hide-show-btn{
    margin: 30px;
  }
  .nav-tabs .nav-item.show, .nav-tabs .nav-item {

    &:not(:first-of-type) {
      margin-left: 60px;
    }

    .nav-link {
      color: #2C2D2F;
      background-color: #fff;
      border: none;
      font-size: 24px;
      font-weight: bold;
      font-family: 'Source Sans Pro';
  
      &.active {
        border-bottom: 4px solid #1473E6;
      }
    }
  }
  .tabs{
    margin-bottom: 70px;
  }
  .tab-content{
      h3{
        color: #1473E6;
        font-family: 'Poppins';
        font-size: 41px;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      h4{
        color: #2C2D2F;
        font-size: 27px;
        margin-bottom: 50px;
          strong{
            font-weight: 600;
          }
      }
      tr{
        font-size: 19px;
        color: #2C2D2F;
      }
  }

  .health-intake-complete {
    font-family: "Source Sans Pro";
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 0.4em;
    margin-top: 25px;
  }

  .heatlth-intake-paragraph {
    font-family: "Poppins";
    font-size: 22px;
    margin-bottom: 40px;
    i{
      background-color: #28a745;
      border-radius: 30px;
      color: #fff;
      padding: 5px;
      font-size: 11px;
      vertical-align: middle;
    }
  }
  .text-success {
    color: #28a745!important;
    vertical-align: middle;
}
}

.MuiPickersToolbar-toolbar {
  background-color: #308c57 !important;
}
.MuiTypography-h5 {
  font-family: "Source Sans Pro" !important;
}
.MuiTypography-subtitle1 {
  font-family: "Source Sans Pro" !important;
}
.MuiDialogActions-spacing button:last-child {
  background-color: #1473e6;
  border-radius: 5rem;
  color: white !important;
}
.MuiDialogActions-spacing button:focus {
  outline: 0px !important;
}
.MuiDialogActions-spacing .MuiButtonBase-root span:focus {
  outline: 0px !important;
}
.MuiPickersBasePicker-pickerView {
  margin-left: 30px;
}

.MuiPickersBasePicker-pickerView
  .MuiPickersYearSelection-container::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.MuiPickersBasePicker-pickerView
  .MuiPickersYearSelection-container::-webkit-scrollbar-thumb {
  background-color: #868687;

  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}
.MuiInputBase-input{
  margin: 13px !important; 
}

@include largeScreenOnly {
  .auth-form {
    .custom-card {
      .form-check {
        .form-check-label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

@include mobileScreen {
  .auth-form {
    .custom-card {
      max-width: 418px;
      width: 100%;
      ul {
        li span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
        }
      }
    }
  }
}

.hide {
  display: none;
}

.stepper-form-sub-headings {
  color: $black;
  font-weight: 500;
  font-family: $font_0;
  margin-bottom: 18px;
}

.form-check-label {
  font-size: 16px;
}

.form-check {
  position: relative;
  text-align: left;

  input[type="checkbox"] {
    position: absolute;
    top: 6px;
    margin-top: 0;
    left: 0;
    margin-left: 0;
  }
}

@include mediumScreen {
  .main-container {
    padding-top: 22px;
  }
}

.fa-question-circle {
  position: relative;

  &:after {
    content: attr(title);
    display: block;
    font-size: 14px;
    color: #333;
    position: absolute;
    right: 0;
    bottom: calc(100% + 5px);
    padding: 8px;
    max-width: 300px;
    background: rgba(241, 204, 20, 0.8);
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    width: max-content;
    opacity: 0;
    transition: all ease 0.275s;
    line-height: 1.3;
    visibility: hidden;
  }

  &:hover:after {
    opacity: 1;
    visibility: visible;
  }
}

@include mobileScreen {
  .bs-stepper .step-trigger {
    padding-bottom: 15px !important;
  }
  .col-lg-8 .bs-stepper-content.registration .bs-stepper-header {
    padding: 0;
    margin: 0;

    .line {
      display: none;
    }
  }
}

.card-body {
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }

  .bs-stepper-content.registration .card-body.custom-inner-content .row.mb-4 {
    margin-bottom: 1.5rem !important;
  }
}

.custom-btn {
  margin-top: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[class*="col-"] {
  position: relative;
}

.concerns-text {
  display: block;
  margin: 5px 0;
}

.d-flex.verify-input {
  input {
    border: none;
    border-bottom: 1px solid #ced4da;
    text-align: center;
    margin-right: 2px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.modal.custom-verify-modal .verify-modal-title {
  margin-bottom: 5px;
  margin-top: 10px;
}

div[textheading="Please enter the OTP we sms you"] .modal-header {
  display: none;
}

#navbarSupportedContent .form-inline {
  margin-left: auto;
}

.pos-relative {
  position: relative;
}


.media-body.mb-auto {
  padding: 0px;
}

.form-control.custom-form-control,
.react-tel-input .form-control {
  background: #ffffff;
  background-color: #ffffff;
}
.label-helper-opacity-1{
  opacity: 1 !important
}
.label-helper-opacity-0{
  opacity: 0 !important
}


.objective-input {
  margin-right: 11px;
  margin-left: 11px;
}
.objective-intake{
  .objective-input{
    margin-right: -4px;
    margin-left: -4px;
  }
}

select.disabled-option {
  color: rgba($black, 0.5);
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 600;
}

.boldest {
  font-weight: 700;
}

.height-100 {
  height: 100%;
}

.mar-x-3 {
  margin-right: 4px;
  margin-left: 4px;
}

.un-emph {
  opacity: 0.7;
  font-weight: normal;
}

.d-in-block {
  display: inline-block;
}
.MuiInput-underline:before {
  border-bottom: 0px !important;
}
.MuiInput-underline:after{
  border-bottom: 0px !important;
}
.MuiPickersDay-daySelected{
  background-color: #1473e6 !important;
}
/* Absolute Center Spinner */
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 15px solid rgba(33, 150, 243, 1.0);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;


}

.physician-profile{
 .img-fluid{
   background-color: #1473E6;
   border-radius: 5rem;
 } 
 p {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
 } 
 .btn-physician{
  position: absolute;
  left: 92px;
  bottom: 127px;
  border-radius: 5rem;
 }
}


/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader,
.loader:before,
.loader:after {
  background: #1473E6;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #1473E6;
  text-indent: -9999em;
  margin: 0px auto;
  position: fixed;
  font-size: 7px;
  transform: translateZ(0);
  animation-delay: -0.16s;
  margin-right: 4px;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


.main-container{
    .name{
      font-family: "Source Sans Pro", sans-serif;
      font-size: 46px;
      font-weight: bold;
      margin-bottom: 0px;
    }
}

@media (max-width: 1024px){
  .pos-relative img{
    width: 80%;
  }
  .stepper-form-headings {
    font-size: 30px;
    margin-top: 20px;
  }
  .media-body p{
    font-size:12px;
  }
  .patient-singup {
    padding: 5rem 2.9rem;
}
}





@media (max-width: 768px) {
  .dashboard-container{
    
    .header-img {
      padding: 20px 10px;
      background-repeat: no-repeat;
    }


    .stepper-form-headings {
      font-size: 30px;
      margin-top: 0px;;
    }

  
  }
  .pos-relative{
    .btn{
      position: relative;
    }
}
.media-body {
  margin-top: 5px;
    p {
      font-family: 'poppins';
      font-size: 15px;
      white-space: break-spaces;
      }
}
.pos-relative img{
  width: 80%;
}

}


@include largeScreen {
  .top-white-box-dashboard{
    .badge-yes {
      position: relative !important;
      top: -116px !important;
      left: 162px !important;
    }
    .badge-no {
      position: relative !important;
      top: -116px !important;
      left: 288px !important;
    }
  }
}


@media (max-width: 768px){
  nav .d-flex{
    display: block !important;
  }
  .navbar-collapse .ml-auto, 
   .navbar-collapse .mx-auto{
    margin-left: unset !important;
  }
  .pp-img {
    top: -56px;
    left: 165px;
  }
  .patient-singup{
    padding: 5rem 1.7rem;
  }
  .nav-link {
    display: block;
    padding: 1.1rem 4.1rem;;
    margin-bottom: -1px;
}
 .bs-stepper .step-trigger{
  margin-top: -9px;
}
#view-patient-shoop .bs-stepper-header .step:first-child:after {
  height: calc(100% - 0rem);
}
.custom-inner-content .stepper-form-headings{
  padding-top: 0px;
}


}

@media (max-width: 426px) {
  .dashboard-container{
   
    .stepper-form-headings {
      font-size: 27px;
    }
    .media-body {
      margin-top: 0px;
      p {
       font-family: 'poppins';
       font-size: 14px;
       white-space: break-spaces;
       }
    }
    .pos-relative img{
      width: 68%;
    }
    .header-img {
      padding: 20px 10px;
      background-repeat: no-repeat;
      .col-md-7{
        border-right: none;
      }
    }
    .pos-relative{
      width: 40%;
        .btn{
          position: relative;
        }
    }
  }
  .dashboard-container .orange .md-step.active .fa-check{
    font-size: 12px;
  }
  .dashboard-container .orange .md-step{
    padding: 0px;
  }
  .dashboard-container .section-2 .btn-primary-edit{
    margin: 7px 0px 7px 35px;
  }
  .top-white-box-dashboard button{
    padding: 0px 10px !important;
  }
  .dashboard-container .tab-content h3{
    font-size: 25px;
  }
  .dashboard-container .tab-content h4{
    font-size: 22px;
    margin-bottom: 35px;
  }
}





@media (max-width: 380px) {
  .dashboard-container .nav-tabs .nav-item.show .nav-link, 
  .dashboard-container .nav-tabs .nav-item .nav-link{
    font-size: 16px;
  }
}




.top-white-box-dashboard{
  margin: 0px 0px;
  background: transparent;
  min-height: 0px;
  border-radius: 0px;
  .feelings-btn{
    text-align: center;
  }
  .badge-yes{
      position: relative;
      top: -25px;
      left: -41px;
      .fa-check{
          background: white;
          color: #308c57;
          font-size: 20px;
          border: 3px solid;
          border-radius: 30px;
      }
  }
  .badge-no{
      position: relative;
      top: -25px;
      left: -155px;
      .fa-check{
          background: white;
          color: #1473E6;
          font-size: 20px;
          border: 3px solid;
          border-radius: 30px;
      }
  }
  .question{
      font-size: 26px;
      font-family: 'Poppins';
      text-align: right;
      font-weight: 400;
      margin: 14px 0px;
  }
  .fa-smile-o{
      font-size: 29px;
      vertical-align: middle;
  }
  button{
    border: none;
    font-size: 24px;
    padding: 7px 18px;
    margin: 10px 10px;
    border-radius: 10px;
    font-family: 'Poppins';
  }
  .btn-primary {
    h5{
      font-size: 18px;
      margin-top: 4px;
    }
  }
  .btn-success{
    h5{
      font-size: 18px;
      margin-top: 4px;
    }
  }
  button:focus,
  button:active{
      outline: none !important;
  }
  .first-line{
      padding: 0px 0px;
  }
}
.consent-card {
   max-width: 727px  !important; 
.consent-paragraph {
  font-size: 18px;
    font-family: 'Source Sans Pro';
}
}

// Page Not Found
.error-template {
  padding: 0px 15px 40px 15px;
  text-align: center;
  width: 100%;
  background: #ffff;
  min-width: 1110px;
  box-shadow: 0px 0px 25px 0px #80808052;
  border-radius: 10px;

  .error-details{
    padding-bottom: 25px;
    font-size: 16px;
    font-family: 'Poppins';
  }
  .action-text{
    padding: 25px 0px;
    font-size: 16px;
    font-family: 'Poppins';
  }
  .emoji {

    img{
      width: 150px;
    }
    span{
      font-size: 10rem;
      vertical-align: middle;
      font-weight: 500;
    }
  }
  .vl {
    font-weight: 900;
  }
}
.error-actions {margin-top:15px;margin-bottom:15px;}
.error-actions .btn { margin-right:10px; }

@media only screen and (min-width: 1600px){

  .top-white-box-dashboard{
    .first-line {
      margin: 0px 39px ;
    }
  }
}




@media (max-width: 1024px){
  .error-template{
    min-width: 928px;
  }
}


@media (max-width: 768px){
  .error-template{
    min-width: 690px;
  }
}

@media (max-width: 426px){
  .error-template{
    min-width: 380px;
  }
  .error-template .emoji span{
    font-size: 5rem;
  }
  .error-template .emoji img {
    width: 90px;
  }
}

@media (max-width: 376px){
  .error-template {
      min-width: 333px;
  }
}


@media (max-width: 321px){
  .error-template {
      min-width: 290px;
  }
}
#profile-update {
  overflow: hidden;
  box-shadow: 0px 0px 25px 0px #80808052;
  border-radius: 15px;
}

.card-body-profile {
  .css-yk16xz-control {
    padding: 8px;
    border-radius: 7px;
    border: 1px solid #d0d0dc;
    overflow: hidden;
  }
  .css-1wa3eu0-placeholder {
    font-family: "Poppins";
    font-weight: 400;
    color: #2c2d2f;
  }

  .my-className-prefix__control--is-focused {
    height: calc(1.5em + 2rem + 2px) !important;
  }
  .switch-label {
    width: 96px;
  }
  .switch-input:checked + .switch-label::after {
    left: 67px;
  }
  .switch-input:checked + .switch-label::before {
    content: "Enabled";
    text-indent: 7%;
  }
  .switch-label::before {
    content: "Disabled";
    margin-left: 20px;
    text-indent: 32%;
  }
  .switch-label::after {
    margin-left: 23px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
  .ant-upload-list-picture-card-container {
    width: 200px;
    height: 200px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    color: red;
  }
  .ant-upload-list-picture-card
    .ant-upload-list-item-actions
    .anticon-eye:hover {
    opacity: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
    opacity: 0;
  }
  .ant-upload-list-picture .ant-upload-list-item-error,
  .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: transparent;
  }
}
.personal-image {
  .marginBottom {
    margin-bottom: 119px;
  }
  .pos-relative {
    position: relative;
  }
  label {
    line-height: 200px;
  }

  img {
    box-shadow: 0 4px 8px #ccc, 0 6px 20px #ccc;
    border-radius: 50%;
    width: 199px;
  }
  .image-btn {
    right: 47px;
    position: relative;
    background-color: #f1cc14;
    color: black;
    font-size: 22px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    top: 76px;
    padding: 1px 8px;

    img {
      width: 19px;
      box-shadow: 0 0px 0px #ccc, 0 0px 0px #ccc !important;
    }
    &:focus {
      outline: none;
    }
  }
  .image-btn:hover {
    background-color: blue;
    color: white;
  }
}


* {
    font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
  }
  .border-radius {
    border-radius: 5rem !important;
}
html{height: 100% !important;}
body {
    height: auto !important;
}
body {
  font-family: 'Poppins', 'sans-serif' !important;
  background-color: #EFEFF1 !important;
  /*overflow-x: hidden;*/
  margin:0;
  padding:0;
}
.main-container{
  padding: 10px;
  padding-bottom: 10px;
  padding-top: 70px;
  overflow-x: hidden;
  max-width: 1300px;
  margin: 0 auto;
}
.MuiFormHelperText-root.Mui-error {
  display: none;
}

@import "_variables.scss";
@import "_mixins.scss";
.expire-margin-left {
  margin-left: -1rem !important;
}
.bs-stepper-header {
  align-items: stretch !important;
  flex-direction: column;
  margin: 0;
}

.bs-stepper .step-trigger {
  padding: 0px;
  font-size: 18px;
  color: #868687;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  z-index: 3;
}
.step.active .bs-stepper-label {
  color: #262626;
  font-weight: 600;
  font-size: 18px;
}
.bs-stepper .step-trigger:hover,
.bs-stepper .step-trigger:focus {
  background: none !important;
}

.bs-stepper .step-trigger.disabled,
.bs-stepper .step-trigger:disabled {
  opacity: 1;
}

.bs-stepper-label.custom-stepper-circle {
  margin: 0px 0.5rem 0rem 1rem;
  color: #868687;
  font-weight: normal;
  white-space: break-spaces;
  text-align: left;
}

.bs-stepper .line,
.bs-stepper-line {
  /* min-height: 36px;
  margin-left: 6px;
  border-left: 2px solid rgba(0, 0, 0, 0.12); */
}

.step {
  .bs-stepper-circle.custom-stepper-circle {
    position: relative;
    background: transparent;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      height: 19px;
      width: 19px;
      border-radius: 50%;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
      border: 2px solid #b0b0b0;
    }
  }

  &.active {
    .bs-stepper-circle.custom-stepper-circle {
      color: rgba(0, 0, 0, 0.12);
      background-color: white;
      border: 2px solid;
      margin: 0px;
      padding: 0px;
      border-radius: 50%;
      z-index: 2;
      border: 2px solid #707070;

      &:after {
        background: #1473e6;
        border: none;
      }
    }
  }

  &.patient-intake-active {
    .bs-stepper-circle.custom-stepper-circle {
      background: #40b46e;

      &:after {
        background: transparent;
        border: none;
      }

      &:before {
        content: "\f00c";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 20px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        color: #ffffff;
      }
    }
  }
}

.step.patient-intake-active .bs-stepper-circle {
  background-color: white;
}
.step.patient-intake-active .bs-stepper-label.custom-stepper-circle {
  color: #40b46e;
  font-weight: 500;
}

#stepper1 {
  overflow: hidden;
  box-shadow: 0px 0px 25px 0px #80808052;
  border-radius: 15px;
}
.registration .custom-form-row{
  margin-bottom: 1.5rem;
}

.bs-stepper-header {
  .step {
    padding: 25px 0;
    position: relative;

    &:last-child {
      padding-bottom: 0;

      &:after {
        height: calc(100% - 14px);
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 5px;
        background: #ffffff;
        z-index: 2;
        height: 20px;
        width: 20px;
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:after {
      content: "";
      position: absolute;
      height: calc(100% + 5px);
      width: 3px;
      background: #c7c9cb;
      left: 14px;
      top: 6px;
      z-index: 1;
    }
  }
}

.register-sidebar {
  padding: 55px 0 50px 45px;
}
.register-sidebar .group-card {
  width: 100%;
  margin-top: 75px;
}
.register-sidebar .group-card img {
  height: auto;
  width: auto;
  margin-top: 25px;
}
.reg-heading {
  color: #1473e6;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 35px;
}
.btn.border-2px {
  border: 2px solid;
}

.stepper-form-headings {
  font-family: $font_1;
  font-size: 30px;
  font-weight: bold;
}
.name{
  font-family: $font_1;
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 0px;
  max-width: 26rem;


}

.profession{
    font-size: 20px;
    color: #767B84;
    font-family: 'Poppins';
    margin-bottom: 1.7rem;
}

.dosing{
  margin-bottom: 20px;
}
.stepper-form-sub-headings {
  font-family: $font_0;
  font-size: 17px;
  font-weight: 900;
}
.stepper-form-paragraph {
  font-family: "Poppins";
  font-size: 18px;
  color: $color_light_grey_color;
}
.custom-inner-content .stepper-form-headings {
  padding-top: 35px;
  padding-bottom: 20px;
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  color: $black;
}
.card-body.custom-inner-content {
  padding: 27px 45px;
  /* display: flex;
  flex-direction: column; */
}
// File Uploader

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 25px;
  border: 1px dashed rgba(90, 82, 82, 40%);
  border-radius: 3px;
  transition: 0.2s;
  background-color: $color_light_green_color;
  &.is-active {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.fake-btn {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 8px 2px;
  margin-right: 0px;
  font-size: 10px;
  color: $color_blue_approx;
  font-family: $font_0;
  font-weight: bold;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}

.form-control.custom-form-control {
  background-color: $color_light_green_color;
  border: 1px solid $color_dim_light_grey_color;
  border-radius: 7px;
  height: calc(1.5em + 2rem + 2px);
}

.form-control.MuiTextField-root {
  background-color: $color_light_green_color;
  border: 1px solid $color_dim_light_grey_color;
  border-radius: 7px;
  height: calc(1.5em + 2rem + 2px);
}

.custom-btn {
  display: inline-grid;
  margin-top: 38px;
  .btn-danger.child-btn {
    padding: 1px 4px;
    font-size: 10px;
    background-color: #ff0000;
    border: 0px;
  }
  .btn-primary.child-btn {
    padding: 1px 4px;
    font-size: 10px;
    background-color: #1473e6;
    border: 0px;
  }
}

.btn.custom-back-btn {
  border: 1px solid #b5b5b9;
  color: #b5b5b9;
}
.fa-trash-o{
  font-size: 18px;
    color: #f5163b;
}
.ant-table-cell a{
  color: #f5163b;
  text-align: right !important;
}
.ml-5{
  text-align: right !important;
  color:green !important;
  margin-left: 2rem !important;
       .fa-refresh{
         margin-right: 5px;
         font-size: 14px;
       }
}
.bowse a{
  color: #007bff !important ;
}
p.ant-upload-text {
  color: #767b84 !important;
}
.ant-upload-drag-icon{
  font-size: 3rem;
  margin: 0px 15px;
  color: #767b84;
}
.ant-table-thead > tr > th {
  background:#767b84 !important;
  color: #fff !important;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 12px !important;
  text-align: center;
  padding-right: 9%;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 12px !important;
}
.ant-table-column-sorters {
  color: #fff !important;
}
.ant-table-cell p{
  margin-top: 0;
  margin-bottom: 0;
}
.ant-table-cell h5{
  margin: 0px !important;
  white-space: normal !important;
  width: 250px;
}
.up-del{
  text-align: right;
}
.ant-table-cell.ant-table-row-expand-icon-cell > .ant-table-row-expand-icon {
  padding: 15px;
  color: #1473E6;
    border: 2px solid #1473E6;
    border-radius: 8px;


  &:before,
  &:after {
    top: 50%;
    right: initial;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 0.5px;
  }

  &.ant-table-row-expand-icon-collapsed {
    &:before {
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
}
     

.wrapper {
  position: relative;
}
i.fa-calendar {
  position: absolute;
  top: 20px;
  right: 10px;
  color: $color_blue_approx;
}
label.verify-label {
  position: absolute;
  top: 17px;
  right: 10px;
  color: $color_blue_approx;
  cursor: pointer;
}
.react-datepicker-wrapper {
  display: block !important;
}

.custom-shadow {
  box-shadow: 40px 10px 1px rgba(0, 0, 0, 0.175);
}

// verify Modal

.modal.custom-verify-modal {
  .modal-content {
    border-radius: 2rem;
  }
  .modal-header {
    border-bottom: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .verify-inner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
  }
  .verify-modal-title {
    color: $color_blue_approx;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 28px;
    font-family: "Poppins";
  }
  .verify-modal-footer {
    justify-content: center;
    border-top: 0px;
  }
  .verify-button {
    color: $color_blue_approx;
  }
  .verify-input {
    align-items: center;
    justify-content: center;
  }
  .verify-custom-form-control {
    color: $color_blue_approx;
    width: 16%;
    font-size: 28px;
    font-weight: bold;
    margin-left: 4px;
    border: none;
  }
  .verify-custom-form-control input:focus {
    color: $color_blue_approx;
    background-color: #fff;
    border-color: $color_blue_approx;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
    font-weight: bold;
  }
  .verify-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color_grey_color;
  }
}

.bs-stepper-content.registration {
  height: 100%;
  padding: 0;

  .bs-stepper-header {
    position: relative;
    padding: 0px 0px 0px 0px;
    margin: 0;
    height: 100%;

    & > form,
    & > form > .card-body {
      height: 100%;
    }

    .line {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }

    .step {
      text-align: left;
      margin: -10px 0px 20px -21px;
      position: relative;
      z-index: 9999;
    }
  }
}

.bs-stepper-content.registration .card-body.custom-inner-content .row.mb-4 {
  margin-bottom: 34px !important;
}
.register-sidebar .group-card h3 {
  font-size: 24px;
  padding-top: 10px;
}

.btn-primary.plus-button {
  padding: 2px 6px 0px 6px;
}
@media (max-width: 1025px){
  .bs-stepper-header .step:after{
    top: 10px;
  }
  .name{
    font-family: $font_1;
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 0px;
    max-width: 10rem;
  
  
  }
}


@media (max-width: 769px){
  #stepper1{
    box-shadow: none;
  }
  .group-card{
    margin-left: 4.3rem !important;
    margin-bottom: 2rem;

    .custom-group{
      display: block !important;
    }
  }
  .step.active .bs-stepper-circle.custom-stepper-circle:after {
    background: #1473e6;
    border: none;
}
  .bs-stepper-header .step:first-child:after{
    height: calc(100% - -51rem);
  }
  .bs-stepper-header .last-step:last-child:after{
    height: 0px;
  }
  .bs-stepper-header .step:last-child:before{
    background: unset;
  }

  .registration .custom-form-row{
    margin-bottom: 1.5rem;
  }
  .active .bs-stepper-circle{
    background: #fff;
  }
  .bs-stepper-header .step:last-child:after{
    height: calc(100% - 30px);
  }
  .patient-singup .bs-stepper{
    margin-left: 15px;
  }
  .patient-singup .bs-stepper-header .step:last-child:after{
    height: calc(100% - -51rem);
  }
  .patient-singup .step{
    display: none;
  }
  .patient-singup .register-sidebar{
    padding-top: 50px;
    padding-bottom: 0px;
    padding-left: 25px;
  }
  .patient-singup .card-body .custom-inner-content{
    padding-left: 15px;
  
  }
  .patient-singup .reg-heading{
    margin-bottom: 0px;
  }

}

@media (max-width: 426px){
  .patient-singup .bs-stepper{
    margin-left: 0px;
    }
  .bs-stepper-header .step:first-child:after {
    height: calc(100% - 0rem);
  }

  .group-card {
    margin-left: 2.5rem !important;
}

.card-body .bs-stepper-content.registration .card-body.custom-inner-content .row.mb-0 {
    margin-bottom: 0rem !important;
}
.patient-singup .custom-inner-content .stepper-form-headings{
  text-align: left;
}
.bs-stepper-header .step:after{
  left: 23px;
}



}


@media (max-width: 767px) {

  .bs-stepper-content.registration .card {
    box-shadow: none !important;
  }
  .bs-stepper-content.registration
    .bs-stepper-header
    .step
    .step-trigger.disabled,
  .bs-stepper-content.registration
    .bs-stepper-header
    .step
    .step-trigger:disabled {
    opacity: 1.25;
  }

  .bs-stepper-content.registration
    .bs-stepper-header
    .step.custom-active
    .bs-stepper-circle {
    background-color: #007bff;
    position: relative;
    margin-top: -7px;
  }

  .bs-stepper-content.registration
    .bs-stepper-header
    .custom-active
    .bs-stepper-circle:after {
    content: "";
    position: absolute;
    height: calc(100% + 13px);
    width: calc(100% + 13px);
    border-radius: 50%;
    border: 1px solid grey;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bs-stepper-content.registration
    .bs-stepper-header
    .step
    .step-trigger
    .bs-stepper-label {
    font-weight: bold;
    color: black;
    font-size: 14px;
  }
  .card-body.custom-inner-content {
    padding: 0px 0px;
  }
  .bs-stepper-content.registration
    .bs-stepper-header
    .card-body
    .stepper-form-paragraph {
    text-align: left;
    font-size: 17px;
    color: grey;
  }
  .bs-stepper-content.registration .card-body.custom-inner-content .row.mb-4 {
    margin-bottom: 0px !important;
  }
  .bs-stepper-content.registration .bs-stepper-header .card-body .row .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .bs-stepper-content.registration
    .bs-stepper-header
    .card-body
    .row
    .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .bs-stepper-content.registration
    .bs-stepper-header
    .card-body.custom-card-body {
    // margin: -25px 0px 0px -48px;
    background-color: transparent;
  }
  .bs-stepper-content.registration .bs-stepper-header .row.mb-4 .col-lg-4.col-4,
  .bs-stepper-content.registration .bs-stepper-header .row.mb-4 .col-lg-3.col-3,
  .bs-stepper-content.registration
    .bs-stepper-header
    .row.mb-4
    .col-lg-1.col-1 {
    max-width: 100%;
    flex: auto;
  }
  .bs-stepper-content.registration
    .bs-stepper-header
    .row.mb-4
    .col-lg-4.col-4
    .stepper-form-sub-headings,
  .bs-stepper-content.registration
    .bs-stepper-header
    .row.mb-4
    .col-lg-3.col-3
    .stepper-form-sub-headings,
  .bs-stepper-content.registration
    .bs-stepper-header
    .row.mb-1
    .col-lg-4.col-1
    .stepper-form-sub-headings {
    padding-top: 10px;
    text-align: left;
    width: 100%;
    margin: 0;
  }
  .bs-stepper-content.registration
    .bs-stepper-header
    .card-body
    .row
    .col-lg-12
    .btn-group {
    display: block;
    width: 100%;
    font-size: 12px;
  }
  .bs-stepper-content.registration
    .bs-stepper-header
    .card-body
    .row
    .col-lg-12
    .btn-group
    label {
    width: 100%;
    font-size: 12px;
    margin: 0 0 10px;
  }
  .bs-stepper-content.registration
    .bs-stepper-header
    .card-body
    .row
    .col-lg-12
    .mb-2 {
    margin: 0 !important;
  }
  .modal {
    z-index: 105099 !important;
  }
  .active .bs-stepper-circle:after {
    content: "";
    position: absolute;
    height: calc(100% + 13px);
    width: calc(100% + 13px);
    border-radius: 50%;
    border: 1px solid grey;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
  }
  .css-yk16xz-control {
    padding-bottom: 0.5rem;
  }
  .stepper-form-sub-headings {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
  }

  .group-card {
    width: 90%;
    margin-bottom: 25px;
  }
  .d-flex.custom-group img {
    height: 50%;
    width: 38%;
  }
  .d-flex.custom-group h3 {
    font-size: 19px;
    margin-top: 22px;
  }
  .bs-stepper-content.registration .bs-stepper-header {
    padding: 0px 0px 0px 20px;
    margin: 0px 18px;
  }
  .bs-stepper-content.registration {
    padding: 0 20px 20px;
  }
  .bs-stepper-content.registration .bs-stepper-header .step {
    margin: 0px 0px 7px -21px;
  }
  .register-sidebar {
    padding: 25px 0 0px 45px;
  }
  #stepper1 {
    box-shadow: none;
  }
  .btn-primary.plus-button {
    padding: 2px 6px 0px 6px;
  }
}
.text-primary {
  cursor: pointer;
}
.btn.skip {
  margin-top: 37px;
  cursor: pointer;
}
.btn span.glyphicon {
  opacity: 0;
}
.btn.active span.glyphicon {
  opacity: 1;
}
.spinner-border {
  margin-top: -7px;
}

.patient-intake {
  overflow-x: hidden;
  .stepper-form-headings.custom-font {
    font-size: 40px;
    padding-top: 70px;
  }
}

.custom-inner-content {
  .range-slider {
    width: 97%;
  }
}

@media (max-width: 520px) {
  .bs-stepper .step-trigger {
    flex-direction: inherit !important;
    padding: 10px;
  }
}

@include largeScreenOnly {
  .custom-inner-content {
    margin: 0 0 0 36px;
    box-shadow: -7px 0px 75px 0px #80808052;
    border-top-right-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 8px;
    background-color: white;
    height: 100%;
  }
}

.custom-form-row.autocomplete-input div {
  z-index: 100 !important;
  width: 100%;
  cursor: pointer;
} 
.custom-form-row.autocomplete-input > div > div {
  position: absolute !important;
  top: calc(100% + 5px) !important;
  left: 0 !important;
  min-width: auto !important;
  width: 100% !important;
  overflow-y: auto !important;
  height: min-content !important;
  max-height: 500px !important;
  border-radius: 7px !important;
  padding: 10px !important;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  padding: 2px 0;
  display: none;
  
  & > div {
    font-size: 14px !important;

    & > div {
      padding: 10px 5px !important;
      border-radius: 7px !important;
    }
  }
}

.search {
  width: 30rem;
  margin: 10rem auto 2rem auto;
  text-align: right;
  position: relative;
}
.search-box {
  border: 4px solid transparent;
  border-radius: 2px;
  font-size: 2rem;
  width: 100%;
  padding: 1rem;
  transition: width 0.3s;
}
.search-box:focus {
  width: 100%;
  outline: none;
  border: 4px solid #08a1b6;
}
.search-btn {
  height: 100%;
  width: 4em;
  margin-top: -2em;
  position: absolute;
  top: 50%;
  right: 0.5rem;

  opacity: 0.2;
  background-color: transparent;
  border: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAACnElEQVR4AcXZsUsbYRjH8e+dh2s1SyAGJwMJuDj1BIcEhJQIOnTq5F+QOf0jIq79A7oFh7aYyVBEkaZDC3awECc1AUXRIqUQotfFocnjJe/dk+b9PKP65Md7z13ee3Uwk2SNHKmngs5TnbDLJQqjA+RYZ4OXuDzvkSYf+cAJ44fPAYFhHeAzVhlqBBGrRoax8KjSJYhRXap4KCVoECiqQQKFLC0CZbXIElOBOwJ9cUchzm2Y5QsveN4tdfY4o00HSDHHPKuUmOV5v/D5SSSJ0MXfIY+HBB55dkIvRIIIvJDR28dnFJ/9kHH0MFaVDehRxlSZnuxAFUMZunKQKBJFUQ4wXTIYqcmPZ5GoFmUEahjw5eJTJI6ivBD4jCS/csrEVZZfU4yQk5OPhrwjcoRygQ0GVdCQf73OUEfisaMkHk1HDJHkYeDX82jlBzo+kCSEyxruwDP/EK1DbsWnhHDFgNTpodWjLgY9NECKfnvoyS4p8wBngN5Z/ABtQK8dP0AH0OuYB5iMqfAAMque7HJtHmAOPdnlxjzAPHqyy5V5gFX0ZJfj8AAn9CvhoeVRol8zPMAuj/xrlhW0Vpg1D3ApflhGR3b4wTlDvI24i4u+w9y0uyVrM213U1qxuy2/Z8bui8m23VezgGW7L6cBLdIWXs9FBAsHFCLCJI9opFMKXEzkkEp/IbK0bEdI0LARQRzVWoigPKy+Z5tlWooIiuP6NhVmAEiPNwLkqHDEw5CGx2wyDQDRI8T7l80U19xwxTFNmpwzKM1nFsyeCw7jFymCAxYjrHDp8r9cUOCUYRZ4Bw6AxVV47QJYXIVXLliNsOSC1Qh/XLAa4ZuDmmIcH1l2AaytwhZfmaAkn/qOb7eYBofJekOJJX7znfccAvwFyB3OeNys7d4AAAAASUVORK5CYII=");
}
.search-btn:hover {
  outline: none;
  opacity: 0.4;
  cursor: pointer;
}
.search-btn:focus {
  outline: none;
  opacity: 0.6;
}

ul.options {
  display: block;
  list-style: none;
  transition: width 0.3s;
  margin: auto;
  position: relative;
}

ul.options li {
  display: block;
  background: white;
  width: 100%;
  border-radius: 2px;
}
ul.options li.option-active:hover:not(.header) {
  background-color: #eee;
  font-weight: bold;
  color: black;
  cursor: pointer;
  transition: 0.3s all;
  font-family: "Poppins";
}

ul.options li.option-active {
  background: whitesmoke;
  font-weight: bold;
  font-family: "Poppins";
  color: black;
  border: 1px solid #ddd;
  margin-top: -1px; /* Prevent double borders */
  background: #f6f6f6;
  padding: 4px;
  text-decoration: none;
  font-size: 14px;
  color: black;
  border-radius: 6px;
}
.no-options {
  color: white;
}

.objective-input {
  input {
    appearance: none;
  }
}


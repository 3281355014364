$black: #2c2d2f;
$color_blue_approx: #1473e6;
$color_green_approx: #308c57;
$color_orange_approx: #ff8f3f;
$color_white_approx: #fff6f0;
$color_grey_color: #868687;
$color_light_grey_color: #3c3c3c;
$color_light_green_color: #f5f9fc;
$color_dim_light_grey_color: #d0d0dc;
$color_dark_yellow: #f1cc14;
//fonts
$font_0: "Poppins";
$font_1: "Source Sans Pro", sans-serif;

.disabled-input {
  input[disabled] {
    background-color: #1473e6;
    color: #1473e6;
  }
  .bg-grey {
    background-color: #f2f2f2;
  }
  .btn-outline-primary-blue-active {
    color: #fff;
    background-color: #1473e6;
    border-color: #1473e6;
  }
  .btn-outline-primary-blue {
    color: #1473e6;
    border-color: #1473e6;
  }
  .objective-input {
    margin-right: -4px;
    margin-left: -4px;
  }
  .font-size-10 {
    font-size: 14px !important;
  }
  .table-td-th {
    padding: 0.25rem !important;
  }
  //  ul{
  //   list-style-type: square;
  //  }
}

.physician-btn-success {
  color: #fff;
  background-color: $color_green_approx;
  border-color: $color_green_approx;
}

.physician-btn-success:hover {
  color: #fff;
  text-decoration: none;
}

// Start physician Signu styling //

.physician-sign-up {
  margin: 70px 0px;

  .img-pencil {
    background: url("../images/Icon-pencil.svg");
  }

  .css-yk16xz-control {
    padding: 8px;
    border-radius: 7px;
    border: 1px solid #d0d0dc;
  }
  .css-yk16xz-control:hover {
    padding: 8px;
    border-radius: 7px;
    border-color: #1473e6;
  }

  .physician-profile-dp {
    box-shadow: 0 1px 18px -6px #aca6a6;
    background-color: #1473e6;
  }
  .physician-singup-body {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 1px 18px -6px #aca6a6;

    .physician-signup-main-heading {
      padding: 0px 15px;

      h3 {
        font-size: 36px;
        color: #2c2d2f;
        font-family: "Source Sans Pro";
        font-weight: bold;
        padding-top: 40px;
        margin: 0px;
        padding-left: 10px;
      }
    }

    .physician-signup-form-main {
      margin: 0px 70px;
      .physician-form {
        padding-top: 40px;
      }

      .physician-profile-image {
        .pos-relative {
          img {
            border-radius: 150px;
          }
          .img-text-danger {
            color: #dc3545;
            position: absolute;
            bottom: 0;
            left: 43px;
          }
          .btn {
            position: absolute;
            top: 188px;
            right: 60px;
            background: #f1cc14;
            border-radius: 5rem;
            height: 50px;
            width: 50px;
          }

          i {
            font-size: 16px;
          }
        }
      }
      .physician-signup-form {
        margin-top: 50px;

        .form-holder {
          margin-right: 30px;

          fieldset {
            label {
              font-size: 14px;
              line-height: 1;
              opacity: 1;
              visibility: visible;
              color: #1473e6;
              margin-left: 20px;
              display: inline;
              background: #fff;
              width: 98px;
              padding: 0px 5px;
              .required {
                color: red;
              }
            }
          }
        }
      }
      .submit-btn {
        text-align: center;
        margin: 50px 0px;
        .btn-primary {
          border-radius: 30px;
          padding: 10px 30px;
          font-size: 20px;
          font-family: "Poppins";
          font-weight: 500;
          vertical-align: middle;
        }
      }
    }
  }
}
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  width: 100%;
}

.form-row {
  margin-top: 30px;
}
.physician-form {
  .custom-form-row {
    margin-bottom: 1.5rem;
  }
}
.form-control.custom-form-control,
.react-tel-input .form-control {
  background-color: #f5f9fc !important;
}
.form-control.custom-form-control:focus {
  background: #ffffff;
  background-color: #fff !important;
}

.auth-form-phyiscian-patient {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 20px;
  padding-top: 70px;
  .custom-card-patient {
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 36px;
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 25px 0px #80808052;
  }
  // .autocomplete-input div {
  //   // display: flex !important;
  //   position: relative;
  //   z-index: 100 !important;
  //   width: 100%;
  //   cursor: pointer;
  //   & > div {
  //     position: absolute !important;
  //     top: calc(100% + 5px) !important;
  //     left: 0 !important;
  //     min-width: auto !important;
  //     width: 100% !important;
  //     overflow-y: auto !important;
  //     height: min-content !important;
  //     max-height: 500px !important;
  //     border-radius: 7px !important;
  //     padding: 10px !important;
  //     text-align: left;
  //     border-radius: 3px;
  //     box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  //     background: rgba(255, 255, 255, 0.9);
  //     padding: 2px 0;
  //     display: none;

  //     & > div {
  //       font-size: 14px !important;

  //       & > div {
  //         padding: 10px 5px !important;
  //         border-radius: 7px !important;
  //       }
  //     }
  //   }
  // }
  .up-del {
    text-align: left;
  }
  .ant-table-pagination-right {
    justify-content: center;
  }
  // .ant-table-thead > tr > th {
  //   padding: 25px 15px;
  // }
  .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column{
    margin: -16px -25px  !important;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 3px;
  }
  .ant-table-filter-trigger-container {
    right: 38px !important;
  }
  .ant-table-wrapper .patient-list-diagnosis {
    overflow: visible;
  }
  .ant-table-column-sorters {
    padding: 16px 29px !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
  padding: 26px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    padding-right: 15px;
  }
  .ant-table-cell .update {
    color: $color_green_approx;
    margin-left: 10px;
  }

  .add-patient {
    .objective-input {
      margin-right: -4px;
      margin-left: -4px;
    }
  }
}
.patient-intake-for-physician {
  .auth-form-phyiscian-patient.patient-physician {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1025px) {
  input[type="text"],
  input[type="email"],
  input[type="number"] {
    width: 100%;
  }
  .pos-relative .physician-profile-dp {
    margin-top: 30px;
  }

  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .btn {
    top: 140px;
    right: 60px;
  }
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .physician-profile-dp {
    width: 170px;
    height: 170px;
  }
}

@media only screen and (max-width: 768px) {
  input[type="text"],
  input[type="email"],
  input[type="number"] {
    width: 100%;
  }
  .physician-signup-form {
    .form-row {
      margin-top: 0px;
    }
  }
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .btn {
    position: absolute;
    top: 140px;
    right: 170px;
  }

  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .submit-btn
    .btn-primary {
    padding: 10px 25px;
    font-size: 15px;
  }
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-form {
    padding-top: 15px;
  }
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .physician-profile-dp {
    margin-left: 175px;
    margin-right: 175px;
  }
}

@media only screen and (max-width: 426px) {
  .physician-sign-up .physician-singup-body .physician-signup-main-heading {
    padding: 0px 15px;
    text-align: center;
  }
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .physician-profile-dp {
    margin-left: 0px;
    margin-right: 0px;
  }
  .physician-profile-image {
    padding-left: 30px;
    .pos-relative {
      margin-bottom: 0px;
    }
  }
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-form {
    padding-top: 0px;
  }

  .physician-form .physicain-name input {
    margin-bottom: 22px;
  }

  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .submit-btn {
    margin: 20px 0px;
  }

  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .submit-btn
    .btn-primary {
    padding: 10px 25px;
    font-size: 15px;
  }

  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .btn {
    top: 145px;
    right: 35px;
  }
  .physician-profile-image .pos-relative {
    text-align: center;
  }
}

.physician-patient-listing {
  .ant-table-tbody {
    cursor: pointer;
  }
  .ant-table-wrapper {
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: grey;
      border-radius: 10px;
    }
    .ant-table-cell-scrollbar {
      -webkit-box-shadow: 0 1px 0 1px #767b84;
      box-shadow: 0 1px 0 1px #767b84;
    }
  }

  .physician-btn-success {
    margin-left: 29px;
    .btn-text {
      font-size: 14px;
    }
    .fa-plus {
      border: 1px solid;
      border-radius: 5rem;
      padding: 3px 3px 1px 3px;
      background: white;
    }
    .fa-plus:before {
      color: $color_green_approx;
    }
  }
}

.ant-table-wrapper {
  .first-patient-column {
    margin-top: 13px;
    margin-left: 6px;
    max-width: 100px;
  }
  // .patient-list-email,
  // .patient-list-phone,

  .patient-list-diagnosis {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .fa-trash-o {
    position: relative;
    cursor: pointer;

    &:after {
      content: attr(title);
      display: block;
      font-size: 14px;
      color: #333;
      position: absolute;
      right: 0;
      bottom: calc(100% + 5px);
      padding: 8px;
      max-width: 300px;
      background: rgba(241, 204, 20, 0.8);
      border-radius: 5px;
      font-family: "Poppins", sans-serif;
      width: max-content;
      opacity: 0;
      transition: all ease 0.275s;
      line-height: 1.3;
      visibility: hidden;
      cursor: pointer;
    }
    &:hover:after {
      opacity: 1;
      visibility: visible;
    }

    .physician-sign-up
      .physician-singup-body
      .physician-signup-form-main
      .physician-profile-image
      .pos-relative
      .btn {
      top: 130px;
      right: 35px;
    }
  }
}

@media only screen and (max-width: 376px) {
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .btn {
    top: 140px;
    right: 20px;
  }
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .physician-profile-dp {
    margin-left: -30px;
  }
}

// End physician Signup styling //

// Start physician Dashboard styling //

.physician-dasboard-start {
  .bg-img {
    background-image: url("../images/physician-header-bg.svg");
    padding: 70px 0px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .total-patient {
    background: white;
    border-radius: 12px;
    padding: 15px 20px;
    max-height: 9rem;
    min-height: 9rem;
    box-shadow: 0 8px 19px -10px #777;
    h4 {
      font-size: 21px;
      color: #1473e6;
      font-family: "Poppins";
      padding-bottom: 8px;
    }
    .count {
      font-size: 34px;
      font-weight: 600;
      font-family: "Poppins";
      vertical-align: middle;
    }
    .fa-plus {
      background-color: #308c57;
      border-radius: 45px;
      color: #fff;
      font-weight: 100;
      padding: 5px 6px;
      font-size: 12px;
    }
    .add-new {
      font-size: 14px;
      font-family: "Source Sans Pro";
      font-weight: 600;
      color: #308c57;
      margin-left: 15px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .top-diagnosis {
    background: white;
    border-radius: 12px;
    padding: 15px 20px;
    max-height: 9rem;
    min-height: 9rem;
    box-shadow: 0 8px 19px -10px #777;
    h4 {
      font-size: 16px;
      color: #308c57;
      font-family: "Poppins";
      padding-bottom: 1px;
    }
    li {
      list-style: none;
      font-size: 12px;
      font-family: "Poppins";
      font-weight: 500;
      padding-bottom: 2px;
      span {
        padding: 2px 7px;
        color: #308c57;
        font-weight: bold;
        font-family: "Poppins";
        font-size: 15px;
      }
    }
  }
  .line-charts-margins {
    margin-bottom: -14px;
    margin-left: 22px;
    margin-top: 12px;
  }
  .top-products {
    background: white;
    border-radius: 12px;
    padding: 15px 20px;
    max-height: 9rem;
    min-height: 9rem;
    box-shadow: 0 8px 19px -10px #777;
    h4 {
      font-size: 21px;
      color: #ff8f3f;
      font-family: "Poppins";
      padding-bottom: 1px;
    }
    li {
      list-style: none;
      font-size: 12px;
      font-family: "Poppins";
      font-weight: 500;
      padding-bottom: 2px;
    }
  }
  .icon {
    text-align: right;
    img {
      width: 100%;
    }
  }
  .chart {
    // padding: 15px 23px;
  }
  .charts-start {
    margin: 30px 0px;
    margin-bottom: 70px;
  }
  .pie-chart {
    margin-bottom: 12px;
  }
  .card {
    border-radius: 12px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .physician-dasboard-start .total-patient .add-new {
    margin-left: 0px;
    padding: 8px;
  }
  .physician-dasboard-start .total-patient h4 {
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 769px) {
  .physician-dasboard-start .icon img {
    width: 100%;
  }
  .physician-dasboard-start .total-patient .add-new {
    padding: 0px;
  }
  .physician-dasboard-start .total-patient h4 {
    padding-bottom: 0px;
  }
  .physician-dasboard-start .top-diagnosis li {
    font-size: 9px;
  }
  .physician-dasboard-start .top-products li {
    font-size: 10px;
  }
  .physician-dasboard-start .total-patient .count {
    font-size: 20px;
  }
  .physician-dasboard-start .total-patient .add-new {
    font-size: 12px;
  }
}

@media only screen and (max-width: 426px) {
  .physician-dasboard-start .total-patient,
  .physician-dasboard-start .top-diagnosis,
  .physician-dasboard-start .top-products {
    margin-bottom: 20px;
  }
  .physician-dasboard-start .icon img {
    width: 35%;
  }

  .physician-dasboard-start .total-patient .count {
    font-size: 36px;
  }
  .top-diagnosis .icon img {
    margin-top: -74px;
  }
  .total-patient .icon img {
    margin-top: -95px;
  }
  .top-products .icon img {
    margin-top: -71px;
  }
  .physician-sign-up .physician-singup-body .physician-signup-form-main {
    margin: 0px 45px;
  }
}
@media only screen and (max-width: 321px) {
  .physician-sign-up
    .physician-singup-body
    .physician-signup-form-main
    .physician-profile-image
    .pos-relative
    .btn {
    top: 140px;
    right: 30px;
  }
  .physician-sign-up .physician-singup-body .physician-signup-form-main {
    margin: 0px 10px;
  }
}

// End physician Dashboard styling //

// Start physician Patient Profile styling //

.physicain-patient-profile {
  .header-bg {
    background-image: url("../images/physician-header-bg.svg");
    padding: 35px 0px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .top-bar {
    background-color: #1473e6;
    background-image: linear-gradient(to right, #1473e6, #aab732);
    color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    .content {
      padding: 15px;
      .blink_me {
        animation: blinker2 0.5s cubic-bezier(1, 0, 0, 1) infinite alternate;
      }
      @keyframes blinker2 {
        to {
          opacity: 0;
        }
      }

      h4 {
        display: inline;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        font-family: "Poppins";
        vertical-align: middle;
      }
      span {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        font-family: "Poppins";
        vertical-align: middle;
      }
      img {
        padding-top: 6px;
      }
      .fa-question-circle {
        vertical-align: middle;
        font-size: 20px;
        padding-left: 15px;
      }
      .fa-times-circle-o {
        float: right;
        font-size: 22px;
        padding-top: 9px;
      }
    }
    .tooltips {
      position: relative;
      display: inline-block;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      vertical-align: middle;
      font-size: 20px;
      padding-left: 15px;
      &:before {
        content: "\f059";
      }
    }

    .tooltips .tooltiptext {
      visibility: hidden;
      font-size: 14px;
      color: #333;
      left: 43px;
      right: calc(100% + 5px);
      padding: 8px;
      max-width: 300px;
      background: rgba(241, 204, 20, 0.8);
      border-radius: 5px;
      font-family: "Poppins", sans-serif;
      width: max-content;
      transition: all ease 0.275s;
      line-height: 1.3;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .tooltips:hover .tooltiptext {
      visibility: visible;
    }
  }
  .basic-details {
    .dp {
      margin-left: 3rem;
      img {
        border-radius: 100px;
        box-shadow: 0 8px 19px -10px #777;
      }
    }
    .patient-name {
      margin-top: 20px;
      margin-bottom: 13px;
      h3 {
        font-family: "Source Sans Pro";
        font-size: 30px;
        font-weight: 600;
        // display: inline;
        vertical-align: middle;
      }
      .age-gander {
        display: inline;
        font-size: 16px;
        background: #1473e6;
        border-radius: 6px;
        padding: 3px 7px;
        font-weight: 500;
        vertical-align: middle;
        color: #fff;
      }
    }

    .health-cmnt {
      font-size: 20px;
      font-family: "Poppins";
      font-weight: 500;
      color: #767b84;
      margin-bottom: 15px;
    }
    .health-issues {
      font-size: 14px;
      font-family: "Poppins";
      font-weight: 600;
      p {
        display: inline;
      }
      span {
        color: #1473e6;
        line-break: anywhere;
      }
      .health-issue-one {
        margin-bottom: 10px;
      }
    }

    .conatct-details {
      background: #fff;
      border-radius: 12px;
      padding: 20px;
      margin-bottom: 25px;
      border-bottom-right-radius: 0;
      clip-path: polygon(0% 0%, 100% 0%, 100% 83%, 93% 100%, 0% 100%);
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        background: #dde2e4;
        height: 30px;
        width: 30px;
      }
      .heading {
        font-size: 20px;
        font-family: "Poppins";
        font-weight: 600;
      }

      .form-check {
        float: right;
        font-size: 12px;
        color: #308c57;
      }
      .cell {
        span {
          font-weight: 600 !important;
        }
      }
      .email {
        span {
          color: #1473e6;
        }
      }
      .phone,
      .cell,
      .email {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 500;
        font-family: "Poppins";
      }
      .phone,
      .cell,
      .email {
        p {
          display: inline;
          padding-bottom: 10px;
        }
      }
    }
    .form-check-input {
      display: none;
    }

    .btn-primary {
      float: left;
      cursor: pointer;
      background-color: #308c57;
      color: #fff;
      border: #308c57;
      border-radius: 30px;
      padding: 10px 22px;
      font-size: 16px;
      font-weight: 500;
      font-family: "Poppins";
    }
    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      float: left;
      cursor: pointer;
      background-color: #1473e6;
      color: #fff;
      border: #1473e6;
      border-radius: 30px;
      padding: 10px 22px;
      font-size: 16px;
      font-weight: 500;
      font-family: "Poppins";
    }

    .btn-primary:hover {
      float: left;
      cursor: pointer;
      background-color: #308c57;
      color: #fff;
      border: #308c57;
      padding: 10px 22px;
      font-size: 16px;
      font-weight: 500;
      font-family: "Poppins";
    }
    .tab-btns {
      margin: 30px 0px 0px 50px;
      .buttons img{
        vertical-align: text-bottom;
      }
    }
    .edit-intake,
    .view-shoop,
    .view-notes,
    .view-patient-profile {
      margin-right: 30px;
    }
  }
  .add-symptoms-body {
    .notes-paragraph {
      font-size: 15px;
      font-family: "Poppins";
      font-weight: 500;
    }
    .notes-heading {
      font-size: 18px;
      font-family: "Poppins";
      font-weight: 600;
    }
    .profile-symptoms {
      .btn-secondary {
        border-radius: 10px;
        margin-left: 15px;
        background-color: #748e9a;
      }
      .padding-fix {
        .btn-primary {
          padding: 6px 7px;
        }
        .btn-secondary {
          padding: 6px 10px;
        }
        .physician-notes-btn {
          position: relative;
          cursor: pointer;
          cursor: auto;
          padding: 6px 10px;
          &:after {
            content: attr(title);
            display: block;
            font-size: 14px;
            color: #333;
            position: absolute;
            right: 0;
            bottom: calc(100% + 5px);
            padding: 8px;
            max-width: 300px;
            background: rgba(241, 204, 20, 0.8);
            border-radius: 5px;
            font-family: "Poppins", sans-serif;
            width: max-content;
            opacity: 0;
            transition: all ease 0.275s;
            line-height: 1.3;
            visibility: hidden;
            cursor: pointer;
          }
          &:hover:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

.patient-intake-for-physician {
  margin: 50px 0px;
}

.field-label {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  color: #2c2d2f;
  padding-left: 10px;
}
.field-not-found {
  color: #6c757d;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
}
.field-text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #2c2d2f;
  display: inline-block;
  padding-left: 15px;
}
.step .bs-stepper-circle.circle-green:after {
  background-color: #40b46e;
}

.pc-notes {
  .card {
    border-radius: 12px;
    border: none;
  }
  margin-bottom: 50px;
  form {
    margin: 20px;

    textarea.notes {
      background-color: #efeff1;
      border: 0px;
      border-radius: 1rem;
      padding: 25px;
    }

    label h3 {
      font-size: 30px;
      font-weight: 600;
      font-family: "Source Sans Pro";
    }
    .notes::placeholder {
      font-size: 21px;
      font-family: "Poppins";
      font-weight: 500;
      opacity: 32%;
    }
    .btn-save-notes {
      background-color: #308c57;
      color: #fff;
      border-radius: 30px;
      padding: 12px 30px;
      font-size: 16px;
      font-family: "Poppins";
      font-weight: 500;
      &:focus {
        box-shadow: none;
      }
    }
    .btn-all-notes {
      color: #1473e6;
      border-radius: 30px;
      padding: 0px;
      font-size: 14px;
      font-family: "Poppins";
      font-weight: 600;
      &:focus {
        box-shadow: none;
      }
    }
    .padding {
      padding-right: 0px !important;
    }
  }
  .patient-deceased-start {
    padding: 20px;

    .form-check-inline {
      margin-top: 18px;

      .form-check-input {
        vertical-align: middle;
      }

      h5 {
        display: inline;
        color: #1473e6;
        border-radius: 30px;
        padding: 0px;
        font-size: 14px;
        font-family: "Poppins";
        font-weight: 600;
        vertical-align: middle;
      }
    }

    .patient-deceased-confirm {
      background-color: #1473e6;
      padding: 12px 30px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 30px;
      float: right;
      &:focus {
        box-shadow: none;
      }
    }
    .custom-form-control,
    .form-check-inline {
      margin-bottom: 20px;
    }
  }
}
.add-new-patient-phys .custom-form-row {
  margin-bottom: 1.5rem;
}
.add-new-patient-btn {
  font-size: 17px;
  border-radius: 30px;
}
@media only screen and (max-width: 1024px) {
  .physicain-patient-profile .basic-details .patient-name h3 {
    font-size: 25px;
    margin-left: 27px;
  }
  .physicain-patient-profile .basic-details .patient-name .age-gander {
    font-size: 12px;
  }
  .physicain-patient-profile .basic-details .health-issues {
    font-size: 12px;
    margin-left: 27px;
  }
  .physicain-patient-profile .basic-details .tab-btns {
    margin: 15px 0px 0px 57px;
  }
  .physicain-patient-profile .basic-details .conatct-details .heading {
    font-size: 16px;
  }
  .physicain-patient-profile .basic-details .conatct-details .phone,
  .physicain-patient-profile .basic-details .conatct-details .cell,
  .physicain-patient-profile .basic-details .conatct-details .email {
    font-size: 12px;
  }
  .pc-notes .patient-deceased-start .form-check-inline h5 {
    font-size: 16px;
  }
  .physicain-patient-profile .basic-details .health-journy {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .dp {
    text-align: center;
    margin-bottom: 20px;
  }
  .physicain-patient-profile .basic-details .patient-name h3 {
    margin-left: 0px;
  }
  .physicain-patient-profile .basic-details .health-issues {
    margin-left: 0px;
  }
  .physicain-patient-profile .basic-details .tab-btns {
    margin: 15px 0px 0px 0px;
  }
  .pc-notes .patient-deceased-start .form-check-inline h5 {
    font-size: 16px;
  }
  .physicain-patient-profile .basic-details .view-shoop,
  .physicain-patient-profile .basic-details .view-notes,
  .physicain-patient-profile .basic-details .health-journy {
    margin-top: 10px;
  }
  .physicain-patient-profile .basic-details .dp {
    margin-left: 0px;
  }
  
}

@media only screen and (max-width: 426px) {
  .physicain-patient-profile .basic-details .health-issues {
    margin-bottom: 20px;
  }
  .physicain-patient-profile .top-bar .content {
    text-align: center;
  }
  .physicain-patient-profile .top-bar .content img {
    padding: 0px;
    margin: 0px 8rem;
  }
  .physicain-patient-profile .basic-details .tab-btns {
    margin: 15px 0px 0px 0px;
  }
  .physicain-patient-profile .basic-details .tab-btns .intake-btns {
    margin-bottom: 20px;
  }
  .pc-notes form .btn-save-notes {
    font-size: 14px;
    padding: 12px 22px;
  }
  .pc-notes form .btn-all-notes {
    padding: 0px;
  }
  .pc-notes form label h3 {
    font-size: 20px;
  }
  .patient-deceased-date {
    input {
      margin-bottom: 20px;
    }
  }
  .pc-notes .patient-deceased-start .form-check-inline {
    margin-bottom: 18px;
  }
  .add-new-patient-btn {
    font-size: 12px;
  }
}

@media only screen and (max-width: 376px) {
  .pc-notes form .btn-save-notes {
    font-size: 11px;
    padding: 10px 12px;
  }
  .pc-notes form .btn-all-notes {
    font-size: 11px;
    padding: 10px 12px;
  }
  .add-new-patient-btn {
    font-size: 10px;
  }
}
@media only screen and (max-width: 321px) {
  .pc-notes form .btn-save-notes {
    font-size: 10px;
    padding: 8px 10px;
  }
  .pc-notes form .btn-all-notes {
    font-size: 10px;
    padding: 8px 10px;
  }
  .pc-notes form label h3 {
    font-size: 15px;
  }
  .physicain-patient-profile .top-bar .content img {
    margin: 0px 6rem;
  }
}

// Start physician Patient Profile Create Shoop styling //

.create-shoop-start {
  .physician-btn-success {
    margin-top: 33px;
    &:focus {
      box-shadow: 0 0 0 0;
    }
  }
  .css-yk16xz-control {
    // height: calc(1.5em + 2rem + 2px);
    border-radius: 7px;
    .css-g1d714-ValueContainer {
      padding: 14px;
    }
    .css-tlfecz-indicatorContainer {
      padding-right: 0px;
      width: 24px;
    }
  }
  .css-1pahdxg-control:hover,
  .css-1pahdxg-control:focus {
    border: 1px solid #1473e6 !important;
    box-shadow: none;
    padding: 12px 0px;
    padding-right: 0px;
  }
  .css-1pahdxg-control {
    border: 1px solid #1473e6 !important;
    box-shadow: none;
    padding: 12px 0px;
    padding-right: 0px;
    border-radius: 7px;
    .css-1gtu0rj-indicatorContainer {
      padding-right: 0px;
      width: 24px;
    }
  }

  .css-tj5bde-Svg {
    fill: #2c2d2f;
  }
  .has-feedback {
    padding: 26px 0px;
    input {
      width: 60%;
      float: right;
      background-color: #f5f9fc;
    }
    input::placeholder {
      color: #2c2d2f;
      opacity: 0.5;
      font-size: 12px;
    }
    i {
      top: 38px;
      right: 23px;
    }
  }
  .create-shoop-btn {
    text-align: right;
    padding: 15px 0px;
    .btn-plus {
      background-color: #308c57;
      color: #fff;
      border-radius: 30px;
      margin-right: 20px;
      border-color: #308c57;
      &:focus {
        box-shadow: 0 0 0 0;
      }
    }
    .btn-save {
      background-color: #1473e6;
      color: #fff;
      border-radius: 30px;
      margin-right: 20px;
      padding: 8px 25px;
    }
    .btn-save-send {
      background-color: #308c57;
      color: #fff;
      border-radius: 30px;
      margin-right: 20px;
      padding: 8px 25px;
    }
    .btn-reset {
      border-radius: 30px;
      padding: 8px 25px;
      background-color: #f1cc14;
      border-color: #f1cc14;
    }
  }
}

.create-shoop-collapse {
  .card {
    margin-bottom: 20px;
    border: 2px solid #1473e6;
    border-radius: 12px;
  }
  .card-header {
    background-color: #fff;
    border: none;
    border-radius: 12px;
    h5 {
      .btn-link {
        text-decoration: none;
        font-size: 24px;
        font-family: "Source Sans Pro";
        font-weight: 600;
        color: #1473e6;
      }
      .btn-link:before {
        float: left;
        font-family: FontAwesome;
        content: "\f196";
        padding-right: 6px;
        color: #1473e6;
        font-size: 29px;
        position: relative;
        bottom: 1px;
        font-weight: 100;
      }
      .collapsed-minus:before {
        float: left;
        content: "\f147";
        font-family: FontAwesome;
        padding-right: 6px;
        color: #1473e6;
        font-size: 29px;
        position: relative;
        bottom: 1px;
        font-weight: 100;
      }
      .btn-link:hover,
      .btn-link:active,
      .btn-link:focus {
        text-decoration: none;
      }

      .btn-remove {
        font-size: 14px;
        font-family: "Poppins";
        color: #d43137;
        vertical-align: middle;
        float: right;
        padding: 13px 10px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

// End physician Patient Profile Create Shoop styling //

// Start physician Patient Profile Import Shoop styling //

.import-shoop-start {
  .modal-content {
    background-color: #efeff1;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1473e6;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1473e6;
  }
  .modal-dialog {
    max-width: 962px;
  }
  .modal-header {
    background-color: #fff;
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
    border-bottom: 0px;
    padding: 20px 40px;
    .modal-title {
      margin-bottom: 0;
      font-size: 30px;
      font-family: "Source Sans Pro";
      font-weight: bold;
      vertical-align: middle;
    }
    .close {
      padding: 0px;
      margin: 0px;
      &:focus {
        outline: none;
      }
      span {
        font-size: 25px;
        font-family: "Source Sans Pro";
        font-weight: 500;
        color: #fff;
        background: red;
        padding: 0px 10px;
        border-radius: 30px;
        vertical-align: middle;
      }
    }
  }
  .modal-body {
    background-color: #efeff1;
    height: 550px;
    overflow-y: auto;
    margin: 30px 20px;
    padding: 0px 20px;
    .card {
      border-radius: 20px;
      border: none;
      margin-bottom: 20px;
      box-shadow: 0px 8px 15px 0px #80808052;

      .card-body {
        padding: 2.25rem 3rem;

        .daignose {
          h5 {
            font-size: 22px;
            font-family: "Poppins";
            font-weight: 500;
            span {
              font-size: 22px;
              font-family: "Poppins";
              font-weight: 500;
              color: #1473e6;
            }
          }
        }
        .mdcn {
          p {
            font-size: 16px;
            font-family: "Poppins";
            font-weight: 400;
            color: #1473e6;
          }
        }
        .send-btn {
          text-align: right;

          .btn-primary {
            font-size: 17px;
            font-family: "Poppins";
            border-radius: 30px;
            text-transform: uppercase;
            padding: 10px 28px;
          }
          .import-btn-success {
            color: #fff;
            background-color: $color_green_approx;
            border-color: $color_green_approx;
            font-size: 17px;
            font-family: "Poppins";
            border-radius: 30px;
            text-transform: uppercase;
            padding: 10px 28px;
          }
          .import-btn-danger {
            color: #fff;
            background-color: red;
            border-color: red;
            font-size: 17px;
            font-family: "Poppins";
            border-radius: 30px;
            text-transform: uppercase;
            padding: 10px 28px;
          }
        }

        .mdcn-status {
          p {
            font-size: 16px;
            font-family: "Poppins";
            font-weight: 500;
            .fa-check {
              background-color: #1473e6;
              border-radius: 50px;
              color: #fff;
              padding: 5px;
              font-size: 14px;
              margin-left: 15px;
            }
          }
        }

        .age-gender {
          text-align: right;
          font-size: 16px;
          font-family: "Poppins";
          font-weight: 500;
          .age {
            margin-right: 55px;
            span {
              color: #1473e6;
            }
          }
          .gender {
            span {
              color: #1473e6;
            }
          }
        }
        .table td,
        .table th {
          font-size: 18px;
          font-family: "Poppins";
          font-weight: 400;
          color: #2c2d2f;
        }
      }
    }
  }
}

// Dosing History

.dosing-history {
  .modal-body {
    .card {
      border-radius: 20px;
      border: none;
      margin-bottom: 20px;
      box-shadow: 0px 8px 15px 0px #80808052;

      .card-body {
        padding: 2.25rem 2rem;

        .daignose {
          h5 {
            font-size: 22px;
            font-family: "Poppins";
            font-weight: 500;
            span {
              font-size: 22px;
              font-family: "Poppins";
              font-weight: 500;
              color: #1473e6;
            }
          }
        }
        .mdcn {
          p {
            font-size: 16px;
            font-family: "Poppins";
            font-weight: 400;
            color: #1473e6;
          }
        }
        .send-btn {
          text-align: right;

          .btn-primary {
            font-size: 17px;
            font-family: "Poppins";
            border-radius: 30px;
            text-transform: uppercase;
            padding: 4px 4px;
          }
          .import-btn-success {
            color: #fff;
            background-color: $color_green_approx;
            border-color: $color_green_approx;
            font-size: 17px;
            font-family: "Poppins";
            border-radius: 30px;
            text-transform: uppercase;
            padding: 10px 28px;
          }
        }

        .mdcn-status {
          p {
            font-size: 16px;
            font-family: "Poppins";
            font-weight: 500;
            .fa-check {
              background-color: #1473e6;
              border-radius: 50px;
              color: #fff;
              padding: 5px;
              font-size: 14px;
              margin-left: 15px;
            }
          }
        }

        .age-gender {
          text-align: right;
          font-size: 16px;
          font-family: "Poppins";
          font-weight: 500;
          .age {
            span {
              color: #1473e6;
            }
          }
        }

        .gender {
          text-align: left;
          font-size: 16px;
          font-family: "Poppins";
          font-weight: 500;
          .gender-child {
            span {
              color: #1473e6;
            }
          }
        }

        .table td,
        .table th {
          font-size: 18px;
          font-family: "Poppins";
          font-weight: 400;
          color: #2c2d2f;
        }
      }
    }
  }
}
@media only screen and (max-width: 769px) {
  .dosing-history .modal-body .card .card-body .send-btn {
    text-align: left;
  }
  .dosing-history .modal-body .card .card-body .age-gender {
    text-align: left;
  }
  .dosing-history .modal-body .card .card-body .mdcn-status p {
    margin-bottom: 8px;
    margin-top: 15px;
  }
  .create-shoop-start .physician-btn-success {
    margin-bottom: 15px;
  }
  .create-shoop-start .create-shoop-btn .btn-reset {
    margin-top: 15px;
  }
  .create-shoop-start .create-shoop-btn .btn-save-send{
    margin-right: 0px;
  }
}
@media only screen and (max-width: 426px) {
  .dosing-history .modal-body .card .card-body .send-btn {
    text-align: center;
  }
  .dosing-history .modal-body .card .card-body .age-gender {
    text-align: center;
  }
  .dosing-history .modal-body .card .card-body .gender {
    text-align: center;
  }
}
@media only screen and (max-width: 376px){
  .create-shoop-start .create-shoop-btn .btn-save-send{
    margin-right: 20px;
    margin-top: 15px;
  }
}



@media only screen and (max-width: 376px){
  .create-shoop-start .create-shoop-btn .btn-save-send,
  .create-shoop-start .create-shoop-btn .btn-save{
    margin-right: 0px;
  }
}


// End physician Patient Profile Import Shoop styling //

// End physician Patient Profile styling //

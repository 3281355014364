@import "_mixins.scss";
@import "_variables.scss";
.symptomps-main,
.first-main{
    background-image: url(../images/Untitled-1.png);
    background-repeat: no-repeat;
    background-size: contain;
    // max-width: 1560px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
}

.main-heading {
  text-align: center;
  h1 {
    font-size: 48px;
    color: #2c2d2f;
    font-family: "Source Sans Pro";
    font-weight: bold;
    padding-top: 40px;
    margin-bottom: 0;
  }
  .sub-heading {
    font-size: 17px;
    font-family: "Poppins";
    margin-bottom: 40px;
    font-weight: 400;
  }
}
.top-white-box {
  background: #fff;
  min-height: 330px;
  border-radius: 20px;
  box-shadow: 0 1px 18px -6px #aca6a6;
  margin: 0px 10px;
  margin-bottom: 70px;
  background: #fff;
  min-height: 330px;
  border-radius: 20px;
  box-shadow: 0 1px 18px -6px #aca6a6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  .form-group{
    margin-left: 5px;
    margin-right: 5px;
  }
  .badge-yes {
    position: relative;
    top: -25px;
    left: -31px;
    .fa-check {
      background: white;
      color: #308c57;
      font-size: 14px;
      border: 3px solid;
      border-radius: 30px;
      padding: 3px;
    }
  }
  .badge-no {
    position: relative;
    top: -25px;
    left: -153px;
    .fa-check {
      background: white;
      color: #1473e6;
      font-size: 14px;
      border: 3px solid;
      border-radius: 30px;
      padding: 3px;
    }
  }
  .no {
    margin-left: 20px;
    padding: 12px 20px;
  }
  .question {
    font-size: 26px;
    font-family: "Poppins";
    text-align: right;
    font-weight: 400;
    margin: 14px 0px;
  }
  .fa-smile-o {
    font-size: 32px;
    vertical-align: middle;
    margin-right: 5px;
  }
  button {
    border: none;
    font-size: 20px;
    padding: 12px 18px;
    margin: 10px 10px;
    border-radius: 10px;
    font-family: "Poppins";
    &:focus {
      outline: 0 !important;
    }
    &:active {
      outline: 0 !important;

    }
  }
  .btn-success.focus, .btn-success:focus {
    box-shadow: 0 0 0 0rem;
  }
  .btn-success:hover{
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  .btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 0rem;
  }
  .btn-primary:hover{
    color: #fff;
    background-color: #0062cc;
    border-color: #0062cc;
  }
  .first-line {
    padding: 30px 100px;
  }
  .nd-line {
    margin: 0px 30px;
    .table thead th {
      vertical-align: bottom;
      border-bottom: aliceblue;
      background: #707070;
      color: #fff;
      font-size: 16px;
      font-family: "Poppins";
      font-weight: 600;
      border: none;
      &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    .height-select {
      height: calc(2.1em + .75rem + 2px);
    }
    .table tbody td {
      padding: 20px 10px 20px 0px;
      border-top: 0px !important;
       
      &:first-of-type {
        padding: 20px 10px 20px 0px;
      }
      &:last-of-type {
        padding: 20px 0px 20px 10px;
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color:#41484b;
        opacity: 1; /* Firefox */
      }
      .css-yk16xz-control{
       width: 104%;
       min-height: 45px;
      }
    }
    table {
      margin-bottom: -10px;
    }
    .css-1wa3eu0-placeholder{
    color: #41484b;
    font-size: 15px;
    font-family: "Poppins";
    font-weight: 400;
    }
    .css-tj5bde-Svg{
      fill: #41484b;
    }
    button {
      border: none;
      font-size: 16px;
      padding: 8px 25px;
      font-family: "Poppins";
      border-radius: 30px;
      float: right;
      margin-right: 0px;
    }
    button:focus,
    button:active {
      outline: none !important;
    }
    input,
    select {
      background: #f5f9fc;
      border: 1px solid #bfcbd6;
      color: #41484b;
      font-size: 15px;
      font-family: "Poppins";
      
    }
}
  .gray-box {
    margin-top: 74px;
    background: #F9F9FA;
    padding: 40px;
    border-radius: 1rem;
    margin-left: 32px;
    margin-right: 32px;
    border: 2px solid #DFE5EB;
    .text-left{
      font-family: 'Source Sans Pro';
      font-size: 20px;
      color: #2c2d2f;
    }
    p{
      font-family: 'Source Sans Pro';
      font-size: 32px;
      color: #2c2d2f;
    }
    h3 {
      font-size: 36px;
      font-weight: bold;
      font-family: "Source Sans Pro";
      text-align: center;
      color: #2c2d2f;
      padding-bottom: 21px;
    }
    .obejective-heading{
      font-size: 20px;
    }
    .obejective-label{
      font-size: 20px;
      margin-top: 1rem;
      margin-left: 14rem;
    }
    h2 {
      font-size: 30px;
      font-weight: bold;
      font-family: "Source Sans Pro";
      text-align: center;
      color: #2c2d2f;
    }
    textarea.form-control {
      height: auto;
      border-radius: 12px;
      font-size: 21px;
      font-family: "Poppins";
      padding: 20px;
      font-weight: 400;
      color: #767b84;
      border: 0px;
    }

    .update-btn {
      border-radius: 30px;
      padding: 8px 25px;
      font-size: 18px;
      font-family: "Poppins";
      font-weight: 400;
      float: right;
      margin-top: -18px;
    }
    .status {
      font-size: 17px;
      font-family: "Poppins";
      color: #767b84;
    }
    .date {
      font-size: 17px;
      font-family: "Poppins";
      color: #000000;
    }
    .time {
      font-size: 17px;
      font-family: "Poppins";
      color: #1473e6;
    }
  }
  .double-btn {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 45px;
    button {
      margin: 0px 10px;
      border-radius: 30px;
      padding: 8px 20px;
    }
  }
}
.body {
  background: #fff;
  margin: 50px 245px 92px;
  border-radius: 20px;
  padding: 30px 50px;
  table {
    table-layout: fixed;
  }
  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    font-family: "Source Sans Pro";
    margin-bottom: 0px;
  }
  .sub-heading {
    text-align: center;
    font-size: 20px;
    font-family: "Source Sans Pro";
    padding: 10px;
  }
  .table thead th {
    vertical-align: middle;
    border: none;
    text-align: center;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 500;
    color: #767b84;
  }
  .table td,
  .table th {
    vertical-align: middle;
    border: none;
    text-align: center;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 400;
    color: #262626;
    &:first-of-type {
      text-align: left;
    }
  }
}
.step-feeling {
  display: flex;
  justify-content: space-between;
  padding-top: 13px;
  flex-wrap: wrap;
  transform: translateX(18px);
  max-width: 378px;
  margin: 0 auto;

  & > div {
    position: relative;

    &:after {
      content: "";
      height: 2px;
      background: #767b84;
      width: calc(100% + 35px);
      position: absolute;
      bottom: calc(100% + 13px);
      left: 0;
    }

    &:last-of-type:after {
      content: none;
    }
  }

  .img-1 {
    position: absolute;
    bottom: calc(100% + 34px);
    left: 0;
    transform: translateX(-39%);
  }

  .margin-left-1 {
    transform: translateX(-50%);
    display: inline-flex;
  }
  .margin-left-2 {
    transform: translateX(-50%);
    display: inline-flex;
  }
  .margin-left-3 {
    transform: translateX(-50%);
    display: inline-flex;
  }
  .margin-left-4 {
    transform: translateX(-50%);
    display: inline-flex;
  }
  .margin-left-5 {
    transform: translateX(-50%);
    display: inline-flex;
  }
}
.checkbox-custom,
.radio-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #707070;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  text-align: center;
  width: 26px;
  height: 26px;
  padding: 2px 5px;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  background: rebeccapurple;
  color: #fff;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #fff;
  background-color: #1473e6;
  font-size: 15px;
  vertical-align: middle;
  border: 0px;
}

// Start add symptomps page//
.add-symptoms-main {
  margin-bottom: 100px;
}
.add-symptoms-body {
  background: #fff;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 25px;
  box-shadow: 0 1px 18px -6px #aca6a6
}

.profile-symptoms {
  border: none;
  .view-symptoms-table{
    padding: 0px 15px;
  }
  .view-symptoms-status{
    padding-left: 28px;
  }
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: none;
  }
  .fa-plus-square-o {
    color: #1473e6;
    font-size: 24px;
  }
  .header-date {
    display: inline;
    margin-left: 5px;
    color: #1473e6;
    font-size: 22px;
    font-weight: 300;
    font-family: "Poppins";
  }
  .header-date p {
    display: inline;
    font-family:'Source Sans Pro';
    font-weight: bold;
    color: #1473e6;
  }
  .btn-primary {
    border-radius: 10px;
    margin-left: 15px;
  }
  .fa-thumbs-down {
    background: red;
    color: #fff;
    padding: 0.4em;
    border-radius: 30px;
    margin: 0px 10px;
  }

  .header-status {
    display: inline;
    font-size: 15px;
    color: #000;
    font-family: "Poppins";
    vertical-align: middle;
    font-weight: 600;
     .font-italic{
         font-weight: 400;
     }
  }

  .btn-success {
    margin-left: 15px;
    border-radius: 10px;
    padding: 8px 15px;
    vertical-align: middle;
    text-transform: uppercase;
    .fa-smile-o {
      font-size: 23px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  .center-span {
    color: #1473e6;
    padding-left: 240px;
  }
  .comment {
    margin: 20px 10px;
  }
  .medicene {
    font-size: 15px;
    font-family: "Poppins";
    font-weight: 500;
  }
}

.card-link:before {
    float: left;
    font-family: FontAwesome;
    content: "\f196";
    padding-right: 6px;
    color: #1473e6;
    font-size: 29px;
    position: relative;
    bottom: 6px;
    font-weight: 100;
}
.collapsed-minus:before {
  float: left;
  content: "\f147";
  font-family: FontAwesome;
  padding-right: 6px;
    color: #1473e6;
    font-size: 29px;
    position: relative;
    bottom: 6px;
    font-weight: 100;
}
.card-link:hover,
.card-link:active,
.card-link:focus {
  text-decoration: none;
}


.great {
  text-align: center;
  h3 {
    color: #308C57;
    font-size: 48px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    margin-bottom: 40px;
  }
}
.feelings-emoji{
  text-align: center;
}
form {
  &#smileys {
    input[type="radio"] {
      -webkit-appearance: none;
      border: none;
      cursor: pointer;
      margin: 0 -18px;
      border: 2px solid #767b84;
      border-radius: 20px;
      color: #767b84;

      &:hover {
        filter: grayscale(0);
      }

      &:focus {
        outline: 0;
      }
      &:checked + .worse {
        filter: grayscale(0);
        position: relative;
        color: white;
        top: 16px;
        right: 66px;
        font-size: 19px;
        font-weight: bold;
      }
      &:checked + .same {
        filter: grayscale(0);
        position: relative;
        top: 16px;
        right: 65px;
        font-size: 19px;
        color: white;
        font-weight: bold;
      }
      &:checked + .better {
        filter: grayscale(0);
        position: relative;
        top: 16px;
        right: 65px;
        font-size: 19px;
        color: white;
        font-weight: bold;
      }
      &.happy::before {
        content: "\f119";
    font-family: FontAwesome;
    font-size: 66px;
    margin: 0px 22px 0px 22px;
    position: relative;
    top: -7px;
      }

      &.neutral::before {
        content: "\f11a";
        font-family: FontAwesome;
        font-size: 66px;
        margin: 0px 22px 0px 22px;
        position: relative;
        top: -7px;
      }

      &.sad::before {
        content: "\f118";
        font-family: FontAwesome;
        font-size: 66px;
        margin: 0px 22px 0px 22px;
        position: relative;
        top: -7px;
      }
      &.sad:hover {
        background-color: white;
      }
      &.sad:checked {
        color: white;
        background-color: $color_blue_approx;
        border: 2px solid $color_blue_approx;

        border-radius: 20px;
      }
      &.happy:hover {
        background-color: white;
      }
      &.happy:checked {
        color: white;
        background-color: $color_blue_approx;
        border: 2px solid $color_blue_approx;
        border-radius: 20px;
      }
      &.neutral:hover {
        background-color: white;
      }
      &.neutral:checked {
        color: white;
        background-color: $color_blue_approx;
        border: 2px solid $color_blue_approx;

        border-radius: 20px;
      }
    }
    input[type="radio"] + .worse {
      position: relative;
      color: #767b84;
      top: 16px;
      right: 66px;
      font-size: 19px;
      font-weight: bold;
    }
    input[type="radio"] + .same {
      position: relative;
      color: #767b84;
      top: 16px;
      right: 65px;
      font-size: 19px;
      font-weight: bold;
    }
    input[type="radio"] + .better {
      position: relative;
      color: #767b84;
      top: 16px;
      right: 65px;
      font-size: 19px;
      font-weight: bold;
    }
  }
}

.feelings-emoji-samll form {
  &#smileys {
    input[type="radio"] {
      -webkit-appearance: none;
      border: none;
      cursor: pointer;
      margin: 0 -18px;
      border: 2px solid #767b84;
      border-radius: 10px;
      color: #767b84;
      padding-bottom: 6px;

      &:hover {
        filter: grayscale(0);
      }

      &:focus {
        outline: 0;
      }
      &:checked + .worse {
        filter: grayscale(0);
        position: relative;
        color: white;
        top: 16px;
        right: 46px;
        font-size: 16px;
        font-weight: 500;
      }
      &:checked + .same {
        filter: grayscale(0);
        position: relative;
        top: 16px;
        right: 44px;
        font-size: 16px;
        color: white;
        font-weight: 500;
      }
      &:checked + .better {
        filter: grayscale(0);
        position: relative;
        top: 16px;
        right: 46px;
        font-size: 16px;
        color: white;
        font-weight: 500;
      }
      &.happy::before {
        content: "\f119";
        font-family: FontAwesome;
        font-size: 33px;
        margin: 0px 22px 0px 22px;
        position: relative;
        top: -7px;
      }

      &.neutral::before {
        content: "\f11a";
        font-family: FontAwesome;
        font-size: 33px;
        margin: 0px 22px 0px 22px;
        position: relative;
        top: -7px;
      }

      &.sad::before {
        content: "\f118";
        font-family: FontAwesome;
        font-size: 33px;
        margin: 0px 22px 0px 22px;
        position: relative;
        top: -7px;
      }
      &.sad:hover {
        background-color: white;
      }
      &.sad:checked {
        color: white;
        background-color: $color_blue_approx;
        border: 2px solid $color_blue_approx;

        border-radius: 10px;
      }
      &.happy:hover {
        background-color: white;
      }
      &.happy:checked {
        color: white;
        background-color: $color_blue_approx;
        border: 2px solid $color_blue_approx;
        border-radius: 10px;
      }
      &.neutral:hover {
        background-color: white;
      }
      &.neutral:checked {
        color: white;
        background-color: $color_blue_approx;
        border: 2px solid $color_blue_approx;

        border-radius: 10px;
      }
    }
    input[type="radio"] + .worse {
      position: relative;
      color: #767b84;
      top: 16px;
      right: 46px;
      font-size: 16px;
      font-weight: 500;
    }
    input[type="radio"] + .same {
      position: relative;
      color: #767b84;
      top: 16px;
      right: 44px;
      font-size: 16px;
      font-weight: 500;
    }
    input[type="radio"] + .better {
      position: relative;
      color: #767b84;
      top: 16px;
      right: 46px;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.load-more button {
  border-radius: 5rem;
}
.text-primary-status{
  color: #1473e6;
  vertical-align: middle;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}


@include doubleExtraLargeScreen {
  .body {
    margin: 50px 415px 92px;
  }
  .top-white-box {
  }
}

.profile-symptoms {
    .card-body{
        font-size: 15px;
        font-family: "Poppins";
        font-weight: 500;
    }
}
.cmnts{
    padding-left: 1px;
    font-size: 16px;
    font-weight: 400;

}
.heading-cmnts{
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 600;
    padding-left: 28px;
    p{
      margin-bottom: 0.5rem;
    }
    .font-italic{
    padding-left: 15px;
    font-size: 15px;
    font-weight: 400;
    }
}



.profile-symptoms{
    .show-header-status{
        font-weight: 400;
    }
}

.padding-fix {
    .btn-primary{
        padding: 8px 18px;
       img{
           width: 23px;
           height: 23px;
           margin-right: 2px;
       }
    }
}




@media only screen and (max-width: 769px) {
  .symptomps-main {

    .top-white-box {
      min-height: 430px;
    }
    .btn-success {
      margin-left: 0px;
    }
  }
  .body {
    .gray-box {
      padding: 20px 20px;
    }
  }
  .profile-symptoms {
    .center-span {
      padding-left: 100px;
    }
  }
  .main-heading h1{
    font-size: 32px;
  }
  .main-heading .sub-heading{
    font-size: 13px;
  }
  .yes-no-btns{
    text-align: center;
  }
  .feelings-emoji-samll form#smileys input[type="radio"] + .better{
    top: -25px;
    right: -88px;
  }
  .top-white-box .gray-box h3{
    font-size: 25px;
  }
  .top-white-box .nd-line .table thead th{
    font-size: 12px;
  }
  .top-white-box .nd-line input, 
  .top-white-box .nd-line select{
    font-size: 11px;
  }
}

@media only screen and (max-width: 1025px) {
  .top-white-box {
    min-height: 380px;
    .btn-success {
      margin-left: 0px;
    }
    .question {
      text-align: center;
    }
    .col-md-6 {
      text-align: center;
    }
  }
  .profile-symptoms {
    .padding-fix {
      padding: 0.75rem 0px;
    }
  }
  .center-span {
    padding-left: 199px;
  }
  .top-white-box .gray-box .obejective-label {
    font-size: 20px;
    margin-top: 1rem;
    margin-left: 0rem;
  }
  .top-white-box .badge-no {
    top: -27px;
    left: -145px;
  }
}
// .profile-symptoms .header-status{
//   font-size: 11px;
// }
.profile-symptoms .btn-success .fa-smile-o{
  font-size: 15px;
}
.profile-symptoms .btn-success{
  font-size: 11px;
}
.text-primary-status{
  font-size: 24px;
}
.padding-fix .btn-primary img{
  width: 15px;
  height: 15px;
}
// .padding-fix .btn-primary{
//   font-size: 11px;
// }
.main-status {
  text-align: right;
  // .font-italic{
  //     font-weight: 400;
  // }
}

@media only screen and (max-width: 426px) {
  .header {
    .top-white-box {
      min-height: 475px;
      .btn-success {
        margin-left: 0px;
      }
      .question {
        text-align: center;
      }
      .col-md-6 {
        text-align: center;
      }
      .first-line {
        padding: 30px 50px;
      }
    }
  }
  .symptomps-main {
    .body {
      margin: 50px 10px 100px;
      padding: 30px 10px;
      .gray-box {
        padding: 60px 40px;
        .time {
          font-size: 14px;
        }
        .date {
          font-size: 14px;
        }
        .status {
          font-size: 14px;
        }
        .update-btn {
          margin-top: 0px;
        }
      }
    }
  }
  .profile-symptoms {
    .Frequency {
      padding-right: 55px;
    }
    .center-span {
      padding-left: 60px;
    }
    .padding-fix {
      padding: 0.75rem 0px;
    }
    .header-date {
      margin-left: 40px;
    }
  }
  .main-status {
    text-align: left;
  }
  .main-heading h1{
    font-size: 25px;
  }
  .main-heading .sub-heading{
    font-size: 11px;
  }
  .top-white-box .badge-no{
    top: -77px;
    left: -5px;
  }
  .top-white-box .gray-box{
    padding: 20px;
  }
  .top-white-box .gray-box textarea.form-control{
    font-size: 12px;
  }
  .top-white-box .gray-box{
    margin-top: 0px;
  }
  .top-white-box .gray-box .status{
    font-size: 12px;
  }
  .top-white-box .gray-box .date{
    font-size: 12px;
  }
  .top-white-box .gray-box .time{
    font-size: 12px;
  }
  .top-white-box .nd-line .table thead th{
    font-size: 10px;
  }
  .top-white-box .nd-line input, 
  .top-white-box .nd-line select{
    font-size: 9px;
  }
  .top-white-box .nd-line button{
    font-size: 12px;
  }
  .top-white-box .badge-yes{
    top: -80px;
    left: 108px;
  }
  form#smileys input[type="radio"]{
    margin: 10px -15px;
  }
  form#smileys input[type="radio"] + .same{
    top: -112px;
    right: -136px;
  }
  #smileys{
    margin: 1px;
  }
}

@media only screen and (max-width: 376px) {
  .profile-symptoms {
    .header-date {
      margin-left: 14px;
    }
  }
  .profile-symptoms .header-date p{
    font-size: 16px;
  }
  .top-white-box .no{
    margin-left: 0px;
  }
  .top-white-box button{
    margin: 10px 8px;
  }
  .top-white-box .badge-no{
    top: -77px;
    left: -15px;
  }
  .feelings-emoji-samll form#smileys input[type="radio"]{
    margin: 0 -19px;
  }
  .top-white-box .badge-yes{
    left: 100px;
    top: -80px;
  }
}

@media only screen and (max-width: 321px){
  .header .top-white-box .btn-success{
    margin-left: 0px;
    position: relative;
    left: 16px;
  }
  .top-white-box .badge-yes{
    left: -12px;
    top: -25px;
  }
  .top-white-box .nd-line .table thead th{
    font-size: 6px;
  }
  .top-white-box .nd-line input, .top-white-box .nd-line select{
    font-size: 5px;
  }
  form#smileys input[type="radio"]{
    margin: 0px;
  }
  form#smileys input[type="radio"] + .worse,
  form#smileys input[type="radio"] + .same,
  form#smileys input[type="radio"] + .better
  {
    top: -32px;
    right: 0px;
  }
  .great h3{
    font-size: 32px;
  }
  .top-white-box .gray-box h3{
    font-size: 19px;
  }
  .top-white-box .gray-box .obejective-heading{
    font-size: 13px;
  }
  .feelings-emoji-samll form#smileys input[type="radio"]{
    margin: 5px -19px;
  }
  .feelings-emoji-samll form#smileys input[type="radio"] + .better{
    top: -55px;
    right: 0px;
  }
  .feelings-emoji-samll form#smileys input[type="radio"] + .same{
    top: 16px;
    right: -42px;
  }
  .top-white-box .nd-line button {
    font-size: 8px;
}

}

@media only screen and (min-width: 1600px) {
  .body {
    margin: 50px 245px 92px;
  }
  .top-white-box {
  }
}

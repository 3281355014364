//colors
$black: #2C2D2F;
$color_blue_approx: #1473E6;
$color_green_approx: #308C57;
$color_orange_approx: #FF8F3F; 
$color_white_approx: #FFF6F0;
$color_grey_color: #868687;
$color_light_grey_color: #3C3C3C;
$color_light_green_color:#F5F9FC;
$color_dim_light_grey_color:#d0d0dc;
$color_dark_yellow: #F1CC14;
//fonts
$font_0:'Poppins';
$font_1: 'Source Sans Pro', sans-serif;

// $fontH6: 18px;


/* square buttons */
.rec.rec-arrow {
    border-radius: 0;
    display: none;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 50%;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

.rec-dot_active {
    background-color: rgba(41, 109, 58, 0.5) !important;
    box-shadow: 0 0 1px 3px rgb(36, 99, 55) !important;
}